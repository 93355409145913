import { takeLatest } from 'redux-saga/effects';
import { registerSaga, loginSaga, getPodcastDataSaga, getAfricanSaga, getHomeBannerSaga, getTopNewsDataSaga, getStaticDataSaga } from './authenticationSaga';

import * as types from '../actions';

export default function* watchUserAuthentication() {
  yield takeLatest(types.REGISTER_USER, registerSaga);
  yield takeLatest(types.LOGIN_USER, loginSaga);
  yield takeLatest(types.GET_PODCAST_DATA, getPodcastDataSaga);
  yield takeLatest(types.GET_AFRICANPER_DATA, getAfricanSaga);
  yield takeLatest(types.GET_BANNER_DATA, getHomeBannerSaga);
  yield takeLatest(types.GET_TOPNEWS, getTopNewsDataSaga);
  yield takeLatest(types.GET_STATIC_DATA,getStaticDataSaga );
}

