import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { message, Space, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticData } from '../actions/authenticationActions';
import Loader from "./loader";
import { get, post } from '../utils/api';
import { API_PREFIX } from '../utils/config';
import HtmlData from "./showHtmlData";

const AllAfricanPersonalities = () => {
    const [podcastType, setPodcastType] = useState([]);
    const [perDataType, setPerDataType] = useState(false);
    const [africanPer, setAfricanPer] = useState("");
    const [africanPerd, setAfricanPerd] = useState("");
    const staticData = useSelector(state => state.user.staticData);
    const [personalitiesData, setPersonalitiesData] = useState([]);
    let dispatch = useDispatch();
    const setStaticData = () => {
        if (staticData.response) {
            let tval1 = staticData.response.data.filter((val) => val._id == "653399725167274c68666b3a")[0];
            setAfricanPer(tval1.Name);
            let tval2 = staticData.response.data.filter((val) => val._id == "6570340134736821ccee0208")[0];
            setAfricanPerd(tval2.Name);
        }
    }
    const getStaticsData = () => {
        dispatch(getStaticData());
    }
    useEffect(() => {
        if (!staticData.response) {
            getStaticsData();
        }
    }, [])

    useEffect(() => {
        setStaticData();
    }, [staticData])

    const getPodcastType = () => {
        get('/api/getPodcastType').then((res) => {
            let data = res.data && res.data.filter((val) => val.personalitiesTypes).map((val) => {
                return ({ value: val.personalitiesTypes, label: val.personalitiesTypes })
            })
            setPodcastType(data);
        })
    }
    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }

    const returnSubStr = (str, char) => {
        let subSTR = str;
        if (str.length > char) {
            subSTR = str.slice(0, char);
        }
        return subSTR + " ..";
    }

    const getDataByType = (val) => {
        const param = { type: "PerSonalities", blogType: val }
        post('/api/getAllData/', param).then((res) => {
            if (res.success && res.data.length > 0) {
                if (perDataType) {
                    setPersonalitiesData(oldArray => [...res.data, ...oldArray]);
                } else {
                    setPersonalitiesData(res.data);
                }
            }
            setPerDataType(true);
        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }

    const getTop50 = (val) => {
        const param = { type: "PerSonalities", blogType: "All" }
        post('/api/getTop10Data/', param).then((res) => {
            if (res.success && res.data.length > 0) {
                setPersonalitiesData(res.data)
            } else {
                setPersonalitiesData([])
            }
        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }

    const handleChange = (e) => {
        let newData = personalitiesData.filter((item) => {
            if (item.blogType !== e) {
                return item
            }
        });
        setPersonalitiesData(newData);
    }

    const handleChange1 = (e) => {
        if (e.trim() !== "Select Personalities") {
            getDataByType(e);
            setPerDataType(e);
        }
    }

    useEffect(() => {
        getPodcastType();
        getTop50();
    }, []);
    return (
        <>
            <Loader></Loader>
            <div className="site-section paddingTopcls bg-light">
                <div className="container" data-aos="fade-up">
                    <div className="row mb-5 topspace">
                        <div className="col-md-12 text-center">
                            <h2 className="font-weight-bold text-black">{africanPer}</h2>
                            <p>{africanPerd}</p>
                        </div>
                    </div>
                    <div className="ddldiv">
                        {podcastType.length > 0 &&
                            <> <Select
                                mode="tags"
                                style={{
                                    width: '100%',
                                }}
                                // onChange={handleChange}
                                onSelect={handleChange1}
                                onDeselect={handleChange}
                                tokenSeparators={[',']}
                                options={podcastType}
                                placeholder="Select Personalities"
                            /></>
                        }
                    </div>
                    <div className="row">
                        {personalitiesData.length > 0 ? personalitiesData.map((item) => {
                            return (
                                <>
                                    <div className="col-md-6 col-lg-4 mb-5 mb-lg-5">
                                        <div className="team-member">
                                            <img onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} alt="Image" className="img-fluid" />
                                            <div className="text">
                                                <h2 className="mb-2 font-weight-light h4">{returnSubStr(item.title, 23)}</h2>
                                                <p className="mb-4"><HtmlData data={returnSubStr(item.description, 125)}></HtmlData></p>
                                                <NavLink type="link"
                                                    to={`/podcastsinglepost?type=${item._id}`}
                                                    state={item}
                                                >Keep Reading..</NavLink>
                                                <div>
                                                    <audio id="player2" preload="none" controls style={{ maxWidth: '100%' }}>
                                                        <source src={API_PREFIX + "uploads/" + item.audiofileName} type="audio/mp3" />
                                                    </audio>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }) : <span className="spanPersonalities">Data Not Found</span>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllAfricanPersonalities;