import { PlusOutlined } from '@ant-design/icons';
import { post, get } from '../../utils/api';
import React, { useState, useEffect } from 'react';
import MESSAGES from '../../utils/constant';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import { useLocation } from 'react-router-dom';
import { API_PREFIX } from '../../utils/config';
import { useNavigate } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  Button,
  Input,
  Select,
  Upload,
  message,
  Col, Row
} from 'antd';

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};
const InsertDataForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [countryType, setCountryType] = useState([]);
  const [isCountry, setIsCountry] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const data = location.state;
  let isEdit = false;
  let ddSelectedVal = null;

  let image = null;
  let audio = null;
  let defaultVal = "Algeria";
  const [messageApi, contextHolder] = message.useMessage();
  let { isDropDown, dropDownVal, dropDownLable, type, uploadType, isAudio, afterInsert } = props;
  if (isDropDown && dropDownVal && dropDownVal[0].value) {
    ddSelectedVal = dropDownVal[0].value;
  }
  const [Uploadfile, setUploadFile] = useState();
  const [audiofile, setUploadAudioFile] = useState();
  const [selectedVal, setSelectedVal] = useState();
  const [titleval, setTitleVal] = useState();
  const [UrlVal, setUrlVal] = useState();
  let rawJsText = "Please Add Description";
  if (data && (data.type == "Podcast" || data.type == "PerSonalities")) {
    rawJsText = data.description;
    isDropDown = true;
    dropDownVal = data.dropDownVal;
    if (data.type == "Podcast") {
      dropDownLable = "Select Podcast";
      uploadType = "Upload Logo";
    } else if (data.type == "PerSonalities") {
      dropDownLable = "Select Personalities";
      uploadType = " Upload Image (only PNG,JPEG)";
    }
    isAudio = true;
    audio = API_PREFIX + "uploads/" + data.audiofileName;
    type = data.type;
    ddSelectedVal = data.blogType;
    image = API_PREFIX + "uploads/" + data.logoImage;
    isEdit = true;
  } else if (data && (data.type == "PartnerShip" || data.type == "blog")) {
    rawJsText = data.description;
    isDropDown = false;
    type = data.type;
    uploadType = "Upload Logo";
    isAudio = false;
    image = API_PREFIX + "uploads/" + data.logoImage;
    isEdit = true;
    if (data.type == "blog") {
      isDropDown = true;
      dropDownVal = data.dropDownVal;
      dropDownLable = MESSAGES.selectBlogType;
      ddSelectedVal = data.blogType;
      if (data.blogType == "Countries") {
        defaultVal = data.countryType;
      }
    }
  }

  const getcountryType = () => {
    get('/api/getCountry').then((res) => {
      if (res.data.length > 0) {
        setCountryType(res.data);
      }
    })
  }

  useEffect(() => {
    if (isDropDown && dropDownVal) {
      if (data && data.blogType == "Countries") {
        getcountryType();
        setIsCountry(true);
      }
    }
  }, [])

  const redirectAfterAddorUpdate = () => {
    if (isEdit) {
      if (type == "PartnerShip")
        navigate('/adminPartnerShip');
      else if (type == "Podcast") {
        navigate('/addPodcast');
      } else if (type == "PerSonalities") {
        navigate('/addPerSonalities');
      }
      else if (type == "blog") {
        navigate('/adminBlog');
      }
    } else {
      afterInsert();
    }
  }
  const setDefaultValueForEdit = () => {
    if (data) {
      setTitleVal(data.title)
    } if (data && data.type == "PartnerShip") {
      setUrlVal(data.partnershipURL)
    }
  }
  useEffect(() => {
    setDefaultValueForEdit();
  }, [data])

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(rawJsText)))
  );

  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);


  const handleUploadFile = (e) => {
    setUploadFile(e.fileList[0]);
  };
  const handleUploadAudioFile = (e) => {
    setUploadAudioFile(e.fileList[0]);
  }
  const handleChange = (value) => {
    setSelectedVal(value);
    if (value == "Countries") {
      getcountryType();
      setIsCountry(true);
    } else {
      setIsCountry(false);
    }
  };
  const handleTitleChange = (e) => {
    setTitleVal(e.currentTarget.value);
  }
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  }
  const handleUrlChange = (e) => {
    setUrlVal(e.currentTarget.value);
  }
  const onFinish = async (e) => {
    try {
      let blogType = "";
      let selCountry = selectedCountry;
      const formData = new FormData();
      const AudioformData = new FormData();
      if (isDropDown && selectedVal) {
        blogType = selectedVal;
      } if (isDropDown && type == "blog") {
        if (!selectedVal) {
          blogType = ddSelectedVal;
        }
        if (selCountry == "" && blogType == "Countries") {
          selCountry = defaultVal;
        }
      } else if ((isDropDown && type == "Podcast") || (isDropDown && type == "PerSonalities")) {
        blogType = dropDownVal[0].value;
      }
      let partnershipURL = "";
      if (type == "PartnerShip") {
        partnershipURL = UrlVal;
      }
      let userDetails = localStorage.getItem("userDetails");
      userDetails = JSON.parse(userDetails);
      let param = {
        title: titleval,
        description: convertedContent,
        type: type,
        blogType: blogType,
        logoImage: "",
        audiofileName: "",
        partnershipURL: partnershipURL,
        countryType: selCountry,
        author: userDetails.fullname
      }
      let resuploadDataUploadFile;
      let resuploadDataAudioFile;

      if (Uploadfile) {
        const fileName = Uploadfile.lastModified + Uploadfile.name;
        formData.append("file", Uploadfile.originFileObj);
        formData.append("fileName", fileName);
        resuploadDataUploadFile = await post("/api/uploadFile/", formData);
        if (resuploadDataUploadFile.fileName) {
          param.logoImage = resuploadDataUploadFile.fileName;
        }
      }
      if (audiofile) {
        AudioformData.append("file", audiofile.originFileObj);
        resuploadDataAudioFile = await post("/api/uploadFile/", formData);
        if (resuploadDataAudioFile.fileName) {
          param.audiofileName = resuploadDataAudioFile.fileName;
        }
      }
      if (isEdit) {
        if (!Uploadfile) {
          param.logoImage = data.logoImage;
        } if (!audiofile) {
          param.audiofileName = data.audiofileName;
        }
        param._id = data._id;
        post("/api/updatePartnership/", param).then(() => {
          messageApi.success(MESSAGES.updateSuccessfully);
          setTimeout(() => {
            redirectAfterAddorUpdate();
          }, 500)
        }).catch((error) => {
          messageApi.error(MESSAGES.somethingWentWrong);
        });
      }
      else {
        post("/api/createPartnership/", param).then(() => {
          messageApi.success(MESSAGES.saveSuccessfully);
          setTimeout(() => {
            redirectAfterAddorUpdate();
          }, 500)
        }).catch((error) => {
          messageApi.error(MESSAGES.somethingWentWrong);
        });
      }
    }
    catch (error) {
      messageApi.error(MESSAGES.somethingWentWrong);
    }
  }

  return (
    <>
      {contextHolder}
      <div className="containerinserPage">
        {isDropDown && <Row className='rowinsertForm'>
          <Col span={8}>
            <span>{dropDownLable}</span>
          </Col>
          <Col span={16}>
            <Select className='ddlinsert' onChange={handleChange} defaultValue={ddSelectedVal}>
              {dropDownVal.map((item) => {
                return <Select.Option value={item.value}>{item.value} </Select.Option>
              })
              }
            </Select>
          </Col>
        </Row>
        }
        {isCountry && <Row className='rowinsertForm'>
          <Col span={8}>
            <span>Select Country</span>
          </Col>
          <Col span={16}>
            <Select className='ddlinsert' onChange={handleCountryChange} defaultValue={defaultVal}>
              {countryType.map((item) => {
                return <Select.Option value={item.countryName}>{item.countryName} </Select.Option>
              })
              }
            </Select>
          </Col>
        </Row>
        }
        <Row className='rowinsertForm'>
          <Col span={8}>
            <span>Title</span>
          </Col>
          <Col span={16}>
            <Input value={titleval} onChange={handleTitleChange} />
          </Col>
        </Row>
        <Row className='rowinsertForm'>
          <Col span={8}>
            <span>description</span>
          </Col>
          <Col span={16}>
            <Editor
              defaultEditorState={editorState}
              onEditorStateChange={setEditorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              wrapperStyle={{ border: "1px solid black" }}
            />
          </Col>
        </Row>
        {type == "PartnerShip" && <Row className='rowinsertForm'>
          <Col span={8}>
            <span>URL</span>
          </Col>
          <Col span={8}>
            <Input onChange={handleUrlChange} value={UrlVal} />
          </Col>
        </Row>
        }
        <Row className='rowinsertForm'>
          <Col span={8}>
            <span>{uploadType + (" (only PNG,JPEG)")}</span>
          </Col>
          <Col span={10}>
            <Upload listType="picture-card" showUploadList={true} accept="image/png, image/jpeg" multiple={false} onChange={handleUploadFile}>
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload>
          </Col>
          {image && <Col span={6}>
            <img src={image} width={100} height={100}></img>
          </Col>}
        </Row>

        {isAudio && <Row className='rowinsertForm'>
          <Col span={8}>
            <span> Upload Audio (only MP3,MP4)</span>
          </Col>
          <Col span={10}>
            <Upload listType="picture-card" showUploadList={true} name='upload' accept="audio/mp3, audio/mp4" multiple={false} onChange={handleUploadAudioFile}>
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload>
          </Col>
          {audio && <Col span={6}>
            <audio controls autoplay loop>
              <source src={audio} type="audio/mpeg" /></audio>
          </Col>}
        </Row>
        }
        <Row>
          <Col span={24} className='clsbtnInsert'>
            <Button type='primary' onClick={() => onFinish()} htmlType="submit">Submit</Button>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default InsertDataForm;
