import React, { useEffect, useRef, useState } from 'react';
import { get } from '../../utils/api';
import { Radio } from 'antd';
import ShowData from './showData';
import InsertDataForm from './insertDataForm';
const AddPodcast = () => {
    const [podcastType, setPodcastType] = useState();
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const getPodcastType = () => {
        get('/api/getPodcastType').then((res) => {
            let data = res.data && res.data.filter((val) => val.podcastTypes).map((val) => {
                return ({ value: val.podcastTypes })
            })
            setPodcastType(data);
        })
    }

    useEffect(() => {
        getPodcastType();
    }, [])   
    const afterInsert=()=>{
        setValue(1);
    }
    return (
        <>
            <div className="site-section" >
                <div className="col-md-12">
                    <h3 className="mb-5">Podcast</h3>
                    {podcastType &&
                        <>  <Radio.Group onChange={onChange} style={{ marginBottom: '20px' }} value={value}>
                            <Radio value={1}>Podcast List</Radio>
                            <Radio value={2}>Add Podcast</Radio>
                        </Radio.Group>
                        <br/>
                            {value == 1 ? <ShowData isDropDown={true} dropDownVal={podcastType} dropDownLable={"Select Podcast"} type={"Podcast"}></ShowData> :
                                <InsertDataForm isDropDown={true} afterInsert={afterInsert} dropDownVal={podcastType} dropDownLable={"Select Podcast"} type={"Podcast"} uploadType={"Upload Logo"} isAudio={true} />
                            }
                        </>
                    }

                </div>
            </div>
        </>
    )
}
export default AddPodcast;