import { useEffect, useState } from "react";
import Podcast from "./podcast";
import BehindTheMap from "./behindTheMap";
import HeaderBanner from "./headerbanner";
import Subcribe from "./subcribe";
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import Loader from "./loader";
import HomeSection from "./homeSection";
import { getPodcatData, getTopNews, getStaticData } from '../actions/authenticationActions';
const Dashboard = () => {
  const podcatData = useSelector(state => state.user.podcast);
  const topNews = useSelector(state => state.user.topNews);
  const staticData = useSelector(state => state.user.staticData);
  const [title1, setTitle1] = useState("")
  const [title2, setTitle2] = useState("")
  let dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const setStaticData = () => {
    if (staticData.response) {
      let tval1 = staticData.response.data.filter((val) => val._id == "653395a95167274c68666b32")[0];
      setTitle1(tval1.Name);
      let tval2 = staticData.response.data.filter((val) => val._id == "653397bd5167274c68666b34")[0];
      setTitle2(tval2.Name);
    }
  }
  useEffect(() => {
    setStaticData();
  }, [staticData])

  const getDataByType = () => {
    const param = { type: "Podcast" }
    dispatch(getPodcatData(param));
  }
  const getStaticsData = () => {
    dispatch(getStaticData());
  }
  const gettopNews = () => {
    const param = { type: "news" }
    dispatch(getTopNews(param));
  }

  useEffect(() => {
    if (!topNews.response) {
      gettopNews();
    }
  }, [])

  useEffect(() => {
    if (!podcatData.response) {
      getDataByType();
    }
  }, [])

  useEffect(() => {
    if (!staticData.response) {
      getStaticsData();
    }
  }, [])

  return (
    <>
      {contextHolder}
      <Loader></Loader>
      <HeaderBanner />
      {
        podcatData && podcatData.response && <>
          <Podcast title={title1} topClass='topPod2' type="podcasts" data={podcatData.response.data}></Podcast>
        </>
      }{
        topNews && topNews.response &&
        <Podcast title={title2} data={topNews.response.data} type="news"></Podcast>
      }
      <BehindTheMap></BehindTheMap>
      <HomeSection></HomeSection>
      <Subcribe />
    </>
  )
}
export default Dashboard;