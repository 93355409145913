import { useEffect, useState } from "react";
import { post } from '../utils/api';
import Loader from "./loader";
import { useLayoutEffect } from "react";
import { API_PREFIX } from '../utils/config';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticData } from '../actions/authenticationActions';
import { NavLink } from "react-router-dom";
import loadjs from "loadjs";
import HtmlData from "./showHtmlData";
import LikeItem from "./likeItem";


const AfricanNews = () => {
    const [data, setGetData] = useState([]);
    const [NewsTitle, setNewsTitle] = useState("");
    const [NewsTitleDec, setNewsTitleDec] = useState("");
    const staticData = useSelector(state => state.user.staticData);
    let dispatch = useDispatch();
    const setStaticData = () => {
        if (staticData.response) {
            let tval1 = staticData.response.data.filter((val) => val._id == "657da8ced62dd356b0e1971e")[0];
            setNewsTitle(tval1.Name);
            let tval2 = staticData.response.data.filter((val) => val._id == "657da8f1d62dd356b0e1971f")[0];
            setNewsTitleDec(tval2.Name);
        }
    }
    const getStaticsData = () => {
        dispatch(getStaticData());
    }
    useEffect(() => {
        if (!staticData.response) {
            getStaticsData();
        }
    }, [])

    useEffect(() => {
        setStaticData();
    }, [staticData])


    const getDataByType = () => {
        const param = { type: "Podcast", blogType: "Top African news" }
        post('/api/getAllData/', param).then((res) => {
            if (res.success && res.data.length > 0) {
                setGetData(res.data)
            } else {
                setGetData([])
            }
        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }

    useEffect(() => {
        getDataByType();
    }, [])

    useLayoutEffect(() => {
        loadjs(['/assest/css/owl.carousel.min.css', '/assest/js/owl.carousel.min.js', '/assest/js/main.js']);
    }, [])

    const defaultIamge = (e) => {
        e.target.src = "./assest/images/default_image.jpg";
    }

    const returnSubStr = (str, char) => {
        let subSTR = str;
        if (str.length > char) {
            subSTR = str.slice(0, char);
        }
        return subSTR + " ..";
    }
    return (
        <>
            <Loader />
            <div className="site-section bg-light block-13">
                <div className="container">
                    {data.length > 0 &&
                        <div>
                            <div className="row mb-5" >
                                <div className="col-md-12 text-center">
                                    <h2 className="font-weight-bold text-black">{NewsTitle}</h2>
                                    <p>{NewsTitleDec}</p>
                                </div>
                            </div>
                            <div className="podcastDiv1news">
                                {
                                    data.map((item) => {
                                        return (<div className="text-center p-3 p-md-5 bg-white podcastAll">
                                            <div className="mb-4">
                                                <LikeItem id={item._id} likebuttonClass="likebuttonAll"></LikeItem>
                                                <img alt="Image" onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} className=" mx-auto img-fluid " />

                                            </div>
                                            <div className="leftAligh">
                                                <span className="h5">{returnSubStr(item.title, 23)}</span>
                                                <p className="para">  {<HtmlData data={returnSubStr(item.description, 125)}></HtmlData>}</p>
                                                <NavLink type="link"                                                   
                                                    to={`/podcastsinglepost?type=${item._id}`}
                                                    state={item}
                                                >Keep Reading..</NavLink>
                                            </div>
                                            <audio id="player2" preload="none" controls style={{ maxWidth: '100%' }}>
                                                <source src={API_PREFIX + "uploads/" + item.audiofileName} type="audio/mp3" />
                                            </audio>
                                        </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    }
                </div>
            </div>
        </>

    )
}
export default AfricanNews;
