import React, { useEffect, useState } from 'react';
import { get } from '../../utils/api';
import { Radio } from 'antd';
import ShowData from './showData';
import InsertDataForm from './insertDataForm';
const AddPerSonalities = () => {
    const [podcastType, setPodcastType] = useState();
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };
    const getPodcastType = () => {
        get('/api/getPodcastType').then((res) => {
            let data = res.data && res.data.filter((val)=>val.personalitiesTypes).map((val) => {                
                return ({ value: val.personalitiesTypes })                
            })
            setPodcastType(data);
        })
    }
    useEffect(() => {
        getPodcastType();
    }, [])
    const afterInsert=()=>{
        setValue(1);
    }
    return (
        <>
            <div className="site-section">               
                <div className="col-md-12">
                    <h3 className="mb-5">Add Personalities</h3>
                    {podcastType &&
                        <>  <Radio.Group onChange={onChange} style={{ marginBottom: '20px' }} value={value}>
                            <Radio value={1}>Personalities List</Radio>
                            <Radio value={2}>Add PerSonalities</Radio>
                        </Radio.Group>
                        <br/>
                            {value == 1 ? <ShowData isDropDown={true} dropDownVal={podcastType} dropDownLable={"Select PerSonalities"} type={"PerSonalities"}></ShowData> :
                                <InsertDataForm isDropDown={true} afterInsert={afterInsert} dropDownVal={podcastType} dropDownLable={"Select PerSonalities"} type={"PerSonalities"} uploadType={"Upload Image"} isAudio={true} />
                            }
                        </>
                    }

                </div>

            </div>
        </>
    )
}
export default AddPerSonalities;