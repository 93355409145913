import { useState, useRef ,useEffect} from 'react';
import { isValidEmail } from '../utils/commonUtils';
import { useSelector } from 'react-redux';
import { post } from '../utils/api';
import { message } from 'antd';
import MESSAGES from '../utils/constant';
const Subcribe = () => {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const ref = useRef(null);
  const [title1, setTitle1] = useState("");
  const staticData = useSelector(state => state.user.staticData);
  const setStaticData = () => {
    if (staticData.response) {
      let tval1 = staticData.response.data.filter((val) => val._id == "653398745167274c68666b37")[0];
      setTitle1(tval1.Name);     
    }
  }
  useEffect(() => {
    setStaticData();
  }, [staticData])

  const mess = (type, val) => {
    messageApi.open({
      type: type,
      content: val,
    });
  }
  const inputValChange = (e) => {
    e.preventDefault();
    try {
      setEmail(e.target.value);
    } catch (error) {
      console.log(error)
    }
  }
  const onfinish = () => {
    const val = isValidEmail(email);
    setIsError(!val);
    if (val) {
      const data = {
        email: email
      }
      post('/api/subcribe/', data).then((res) => {
        if (res.success) {
          mess('success', MESSAGES.thanksForSubcribe);
        } else {
          mess('error', MESSAGES.somethingWentWrong);
        }
      }).catch((error) => {
        const val = error.response;
        if (val && val.data && val.data.error.code == 11000) {
          mess('error', MESSAGES.emailAlreadySubcribed);
        } else {
          mess('error', MESSAGES.somethingWentWrong);
        }
      });
    }
    ref.current.value = "";
  }

  return (
    <>
      {contextHolder}
      <div className="site-blocks-cover overlay inner-page-cover clsSubcribeStyle" data-aos="fade" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
              <h2>{title1}</h2>
              <div className="site-block-subscribe">
                <div className="input-group mb-3">
                  <input type="text" ref={ref} onChange={(val) => { inputValChange(val) }} className="form-control border-secondary text-white bg-transparent" placeholder="Enter Email" aria-label="Enter Email" aria-describedby="button-addon2" />
                  <div className="input-group-append">
                    <button className="btn btn-info buttonColor" onClick={onfinish} type="button" id="button-addon2">Send</button>
                  </div>
                </div>
              </div>
              {isError ? <span className='spnError'>{MESSAGES.enterValidEmail}</span> : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Subcribe;