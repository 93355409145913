import { PlusOutlined } from '@ant-design/icons';
import { post, get } from '../../utils/api';
import React, { useEffect, useState, useRef } from 'react';
import MESSAGES from '../../utils/constant';
import { API_PREFIX } from '../../utils/config';
import {
    Button,
    Form,
    Input,
    Upload,
    message,
    Table,
    Switch,
    Image,
    Col, Row, Popconfirm
} from 'antd';


const HeaderBanner = () => {
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [Uploadfile, setUploadFile] = useState();
    const [allBanner, setAllBanner] = useState();
    const [title, setTitle] = useState();
    const [isEdit, setisEdit] = useState(false);
    const [editVal, setEditVal] = useState(null);
    const ref = useRef(null);
    const handleUploadFile = (e) => {
        setUploadFile(e.fileList[0]);
    };
    const onChangeInput = (e) => {
        setTitle(e.currentTarget.value);
    }

    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }
    const onEdit = (e) => {
        setTitle(e.headerText)
        setisEdit(true);
        setEditVal(e._id);
        ref.current.focus();
    }
    const onCancel = () => {
        setisEdit(false);
        setTitle("")
    }
    const onDelete = (e) => {
        let param = {
            id: e,
        }
        post("/api/deleteBanner/", param).then((res) => {
            if (res.success) {
                messageApi.success(MESSAGES.DeletedSuccessfully);
                getBannerValue();
            } else {
                messageApi.error(MESSAGES.somethingWentWrong);
            }
            console.log(res);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        });
    }
    const confirm = (e) => {
        onDelete(e)
    };
    const cancel = (e) => {
        console.log("cancel")
    };

    const onChange = async (checked) => {
        console.log(`switch to ${checked}`);
        let param = {
            id: checked
        }
        //allBanner.filter(()=>{});

        post("/api/updateHeaderBanner/", param).then((res) => {
            messageApi.success(MESSAGES.saveSuccessfully);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        });
        const index = allBanner.findIndex(x => x._id == checked);
        let clonArray = allBanner.slice();
        clonArray[index].isRunning = !clonArray[index].isRunning;
        setAllBanner(clonArray);
    };

    const getBannerValue = () => {
        get('/api/getBanner').then((res) => {
            if (res.data.length > 0) {
                const val = res.data.filter((data) => data.isHeader)
                setAllBanner(val);
            }
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        })
    }
    useEffect(() => {
        getBannerValue();
    }, [])



    const onFinish = async () => {
        try {

            let param = {
                headerText: title,
                ImageURL: "",
                isHeader: true,
                ifFounder:false
            }
            if (Uploadfile) {
                const fileName = Uploadfile.lastModified + Uploadfile.name;
                const formData = new FormData();
                formData.append("file", Uploadfile.originFileObj);
                formData.append("fileName", fileName);
                const resuploadDataUploadFile = await post("/api/uploadFile/", formData);
                if (resuploadDataUploadFile.fileName) {
                    param.ImageURL = resuploadDataUploadFile.fileName;
                }
            }
            if (!isEdit) {
                post("/api/addBanner/", param).then(() => {
                    messageApi.success(MESSAGES.saveSuccessfully);
                    setTitle("");
                    getBannerValue();
                }).catch((error) => {
                    messageApi.error(MESSAGES.somethingWentWrong);
                });
            } else {
                param._id = editVal;
                post("/api/updateBanner/", param).then(() => {
                    messageApi.success(MESSAGES.saveSuccessfully);
                    setTitle("");
                    setisEdit(false)
                    getBannerValue();
                }).catch((error) => {
                    messageApi.error(MESSAGES.somethingWentWrong);
                });
            }
        }
        catch (error) {
            messageApi.error(MESSAGES.somethingWentWrong);
        }
        form.resetFields();
    }
    const formTailLayout = {
        wrapperCol: {
            span: 9,
            offset: 8,
        },
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'headerText',
            key: 'headerText',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Image',
            dataIndex: 'logoImage',
            render: (text, record) => (<Image width={160} height={160} onError={defaultIamge} src={API_PREFIX + "uploads/" + record.ImageURL} />)
        },
        {
            title: 'Active',
            dataIndex: 'audiofileName',
            render: (text, record) => (<><Switch checked={record.isRunning} onChange={() => onChange(record._id)} /> </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => ( //All data present in Record
                <span>

                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={() => confirm(record._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>Delete</Button>
                    </Popconfirm>
                    <Button className='clsbtnBanner' onClick={() => onEdit(record)}>Edit Title</Button>
                </span>
            ),
        },
    ]
    return (
        <>
            {contextHolder}
            <div className='clsBannerUpdate'>
                <Row className='rowinsertForm'>
                    <Col span={8}>
                        <span> Please enter title!</span>
                    </Col>
                    <Col span={16}>
                        <Input maxLength={55} ref={ref} showCount value={title} onChange={onChangeInput} />
                    </Col>
                </Row>
                {!isEdit && <Row className='rowinsertForm'>
                    <Col span={8}>
                        <span> Upload Banner (only PNG,JPEG)</span>
                    </Col>
                    <Col span={16}>
                        <Upload listType="picture-card" showUploadList={true} accept="image/png, image/jpeg" multiple={false} onChange={handleUploadFile}>
                            <div>
                                <PlusOutlined />
                                <div
                                    style={{
                                        marginTop: 8,
                                    }}
                                >
                                    Upload
                                </div>
                            </div>
                        </Upload>
                    </Col>
                </Row>}
                <Row>
                    <Col span={24}>
                        <Button type='primary' className='clsbtnBanner' onClick={() => onFinish()} htmlType="submit">Submit</Button>
                        {isEdit &&

                            <Button type='primary' onClick={() => onCancel()} htmlType="submit">Cancel</Button>
                        }
                    </Col>
                </Row>
            </div>

            <Table columns={columns} className='tblBanner' dataSource={allBanner} />
        </>

    );
};
export default HeaderBanner;
