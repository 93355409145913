const MESSAGES = {
    invalidLogin: 'Invalid login details!',
    somethingWentWrong: 'Something went Wrong!',
    thanksForLogin: 'Thanks for login!',
    thanksForSubcribe: 'Thanks For Subcribe',
    enterValidEmail: 'Enter Valid Email',
    emailAlreadySubcribed: 'This Email Already Subcribed',
    selectBlogType: 'Select Blog Type',
    saveSuccessfully: 'Save Successfully',
    noDataAvailable: 'No Data Availabale',
    updateSuccessfully: 'Update Successfully',
    DeletedSuccessfully: 'Deleted Successfully'

}
export default MESSAGES;