
import React, { useState } from 'react';
import {
    HeartFilled, HeartTwoTone
} from '@ant-design/icons';
import { post } from '../utils/api';
import { Space } from 'antd';

const LikeItem = ({ id, likebuttonClass }) => {
    const [color, setColor] = useState("#eb2f96");
    const [IsSpin, setIsSpin] = useState(false);
    const [isClick, setIsclick] = useState(true);
    const onlikeClicked = () => {
        if (isClick) {
            setColor("#52c41a");
            setIsclick(false);
            updateCount();
        }
    }
    const updateCount = () => {
        const param = { id: id }
        post('/api/updateLike/', param).then((res) => {
            if (res.message && res.data.length > 0) {
                console.log("Update successfully");
            } else {
                console.log("not Updated");
            }
        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }
    
    const spinFalse = () => {
        setIsSpin(true);
        setTimeout(() => {
            setIsSpin(false)
        }, 2000)
    }
    return (
        <>
            <Space className={likebuttonClass}>
                <HeartTwoTone spin={IsSpin} onFocus={() => spinFalse()} twoToneColor={color} onClick={() => onlikeClicked()} />
            </Space>

        </>
    )
}
export default LikeItem;