/**
 * process.env items are configured in .env files or environment variables
 *   and can be overriden at build time
 * see https://facebook.github.io/create-react-app/docs/adding-custom-environment-variables
 */

/** prefix for all api calls */

// export const API_PREFIX = process.env.REACT_APP_API_PREFIX;
//export const API_PREFIX="https://mosespodcastback-35f87076b748.herokuapp.com/";

//export const API_PREFIX="http://192.168.1.7:3012/";
//export const API_PREFIX="http://localhost:3012/";
export const API_PREFIX="http://184.168.30.209:8000/";

export const DEPLOY_PLATFORM =
  'localhost' === window.location.hostname || 'lcl' === window.location.hostname
    ? 'local'
    : 'user.globalvetlink.com' === window.location.hostname
    ? 'production'
    : 'quality';
export const BASENAME = process.env.REACT_APP_USER_BASE_NAME;
export const BACKEND_BASENAME = process.env.REACT_APP_BACKEND_BASENAME;
