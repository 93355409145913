export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const RESET_STORE='RESET_STORE';

export const GET_PODCAST_DATA = 'GET_PODCAST_DATA';
export const PODCAST_DATA_SUCCESS = 'PODCAST_DATA_SUCCESS';
export const PODCAST_DATA_ERROR = 'PODCAST_DATA_ERROR';


export const GET_TOPNEWS = 'GET_TOPNEWS_DATA';
export const TOPNEWS_DATA_SUCCESS = 'TOPNEWS_DATA_SUCCESS';
export const TOPNEWS_DATA_ERROR = 'TOPNEWS_DATA_ERROR';

export const GET_AFRICANPER_DATA = 'GET_AFRICANPER_DATA';
export const AFRICANPER_DATA_SUCCESS = 'AFRICANPER_DATA_SUCCESS';
export const AFRICANPER_DATA_ERROR = 'AFRICANPER_DATA_ERROR';

export const GET_BANNER_DATA = 'GET_BANNER_DATA';
export const PODCAST_BANNER_SUCCESS = 'PODCAST_BANNER_SUCCESS';
export const PODCAST_BANNER_ERROR = 'PODCAST_BANNER_ERROR';

export const GET_STATIC_DATA = 'GET_STATIC_DATA';
export const PODCAST_STATIC_SUCCESS = 'PODCAST_STATIC_SUCCESS';
export const PODCAST_STATIC_ERROR = 'PODCAST_STATIC_ERROR';
