import { useState, useRef, useEffect } from 'react';
import { isValidEmail } from '../utils/commonUtils';
import { useSelector } from 'react-redux';
import { post } from '../utils/api';
import { message } from 'antd';
import MESSAGES from '../utils/constant';
const ContactUs = () => {
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [messagel, setMessagel] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isError, setIsError] = useState(false);
  const ref = useRef(null);
  const mess = (type, val) => {
    messageApi.open({
      type: type,
      content: val,
    });
  }
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }, []);

  const emptyFIeld = () => {
    setFname("");
    setLname("");
    setEmail("");
    setPhone("");
    setMessagel("");
  }

  const onSubmit = () => {
    let val = isValidEmail(email);
    if (!val) {
      mess('error', "Please Enter valid Email");
      return;
    } else if (fName === "") {
      mess('error', "Name is Required");
      val = false;
      return;
    } else if (lName === "") {
      mess('error', "Name is Required");
      val = false;
      return;
    } else if (messagel === "") {
      mess('error', "Message is Required");
      val = false;
      return;
    }
   
    setIsError(!val);

    if (val) {
      const data = {
        fname: fName,
        lname: lName,
        email: email,
        phone: phone,
        message: messagel
      }
      post('/api/createContactUS/', data).then((res) => {
        if (res.success) {
          mess('success', "We will reach you soon");
          emptyFIeld();
        } else {
          mess('error', MESSAGES.somethingWentWrong);
        }
      }).catch((error) => {
        mess('error', MESSAGES.somethingWentWrong);
      });
    }
  }
  return (
    <>
      {contextHolder}
      <div class="site-section">
        <div class="container" data-aos="fade-up">
          <div class="row">
            <div class="col-md-7 mb-5">
              <div class="bg-white">
                <div class="clscontact" ref={ref}>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label for="c_fname" class="text-black">First Name <span class="text-danger">*</span></label>
                      <input type="text" required value={fName} onChange={(e) => setFname(e.target.value)} class="form-control" id="c_fname" name="c_fname" />
                    </div>
                    <div class="col-md-6">
                      <label for="c_lname" class="text-black">Last Name <span class="text-danger">*</span></label>
                      <input type="text" required value={lName} onChange={(e) => setLname(e.target.value)} class="form-control" id="c_lname" name="c_lname" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="c_email" class="text-black">Email <span class="text-danger">*</span></label>
                      <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} class="form-control" id="c_email" name="c_email" />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="c_subject" class="text-black">Phone </label>
                      <input type="text" required  maxLength={10} value={phone} onChange={(e) => setPhone(e.target.value)} class="form-control" id="c_subject" name="c_subject" />
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="c_message" class="text-black">Message </label>
                      <textarea name="c_message" required value={messagel} onChange={(e) => setMessagel(e.target.value)} id="c_message" cols="30" rows="7" class="form-control"></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-lg-12">
                      <input type="submit" class="btn btn-primary btn-lg backgroundcolorbtn" onClick={() => onSubmit()} value="Send Message" />
                    </div>
                  </div>
                 
                </div>
               
              </div>
            </div>
            <div class="col-md-5">
            <div class="p-4 mb-3 bg-white">
              <p class="mb-0">Address</p>
              <p class="mb-4">203 Fake St. Mountain View, San Francisco, California, USA</p>

              <p class="mb-0">Phone</p>
              <p class="mb-4"><a href="#">+1 232 3235 324</a></p>

              <p class="mb-0">Email Address</p>
              <p class="mb-4"><a href="#">youremail@domain.com</a></p>

            </div>
            
          </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ContactUs;