import { useEffect, useState } from "react";
import { Table, message, Button, Popconfirm } from 'antd';
import { get, post } from '../../utils/api';
import MESSAGES from '../../utils/constant';
const AllContact = () => {
    const [subcribers, setSubcribers] = useState();
    const [messageApi, contextHolder] = message.useMessage();

    const getSubcriber = () => {
        get('/api/getContact').then((res) => {
            setSubcribers(res.data);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        })
    }
    const onDelete = (e) => {
        let param = {
            id: e,
        }
        post("/api/deleteContact/", param).then((res) => {
            if (res.success) {
                messageApi.success(MESSAGES.DeletedSuccessfully);
                getSubcriber();
            } else {
                messageApi.error(MESSAGES.somethingWentWrong);
            }
            console.log(res);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        });
    }
    const confirm = (e) => {
        onDelete(e)
    };
    const cancel = (e) => {
        console.log("cancel")
    };

   
    const columns = [
        {
            title: 'First Name',
            dataIndex: 'fname',
            key: 'fname',
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            key: 'lname',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => ( //All data present in Record
                <span>
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={() => confirm(record._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>Delete</Button>
                    </Popconfirm>

                </span>
            ),
        },
    ]


    useEffect(() => {
        getSubcriber();
    }, [])
    return (
        <>
            {contextHolder}
            <div className="site-section" >
                <div className="col-md-12">
                    <h3 className="mb-5">All Contact</h3>
                    <br />
                    {subcribers && subcribers.length > 0 ?
                        <Table columns={columns} defaultSortOrder="descend" filtered={true} dataSource={subcribers} /> : <> <br /><br /><br /><span>{MESSAGES.noDataAvailable}</span></>
                    }

                </div>
            </div>
        </>
    )
}
export default AllContact;