import React, { useEffect, useState } from 'react';
import { Table, Select, message, Button, Image, Popconfirm, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { post, get } from '../../utils/api';
import MESSAGES from '../../utils/constant';
import { API_PREFIX } from '../../utils/config';
import HtmlData from '../showHtmlData';
import Loader from '../loader';

const ShowData = (props) => {
    const { isDropDown, dropDownVal, dropDownLable, type } = props;
    const navigate = useNavigate();
    let StaticDropDownVal = "";
    if (dropDownVal) {
        StaticDropDownVal = dropDownVal[0].value;
    }
    const [selectedVal, setSelectedVal] = useState(StaticDropDownVal);
    const [allData, setAllData] = useState();

    const [countryType, setCountryType] = useState([]);
    const [isCountry, setIsCountry] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("Algeria");
    const [messageApi, contextHolder] = message.useMessage();
    const onDelete = (e) => {
        let param = {
            id: e,
        }
        post("/api/deleteData/", param).then((res) => {
            if (res.success) {
                messageApi.success(MESSAGES.DeletedSuccessfully);
                getData(selectedVal);
            } else {
                messageApi.error(MESSAGES.somethingWentWrong);
            }
            console.log(res);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        });
    }
    const onEdit = (e) => {
        console.log("Edit");
        const data = {
            ...e, dropDownVal: dropDownVal
        }
        navigate('/InsertData', { state: data });
    }
    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }
    const confirm = (e) => {
        onDelete(e)
    };
    const cancel = (e) => {
        console.log("cancel")
    };
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: text => <HtmlData data={text}></HtmlData>,
        },
        {
            title: 'URL',
            dataIndex: 'partnershipURL',
            key: 'partnershipURL',
        },
        {
            title: 'Image',
            dataIndex: 'logoImage',
            render: (text, record) => (<Image onError={defaultIamge} width={160} height={160} src={API_PREFIX + "uploads/" + record.logoImage} />)
        },
        {
            title: 'Audio',
            dataIndex: 'audiofileName',
            render: (text, record) => (<><audio controls autoplay loop>
                <source src={API_PREFIX + "uploads/" + record.audiofileName} type="audio/mpeg" /></audio>  </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => ( //All data present in Record
                <span className='dataAction'>
                    <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this task?"
                        onConfirm={() => confirm(record._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button>Delete</Button>
                    </Popconfirm>
                    <Button onClick={() => onEdit(record)}>Edit</Button>
                </span>
            ),
        },
    ].filter((item) => {
        if ((type == 'blog' && item.title == "Audio") || (type == 'blog' && item.title == "URL")) {
            return;
        }
        else if (type == 'PartnerShip' && item.title == "Audio") {
            return;
        }
        else if (type == 'Podcast' && item.title == "URL") {
            return;
        }
        else if (type == 'PerSonalities' && item.title == "URL") {
            return;
        }
        return item;
    });

    // const data = [
    //     {
    //         key: '1',
    //         name: 'John Brown',
    //         age: 32,
    //         address: 'New York No. 1 Lake Park',
    //         tags: ['nice', 'developer'],
    //     },
    //     {
    //         key: '2',
    //         name: 'Jim Green',
    //         age: 42,
    //         address: 'London No. 1 Lake Park',
    //         tags: ['loser'],
    //     },
    //     {
    //         key: '3',
    //         name: 'Joe Black',
    //         age: 32,
    //         address: 'Sidney No. 1 Lake Park',
    //         tags: ['cool', 'teacher'],
    //     },
    // ];

    const handleChange = (val) => {
        getData(val);
        setSelectedVal(val);
        if (val == "Countries") {
            getcountryType();
            setIsCountry(true);
        } else {
            setIsCountry(false);
        }
    }
    const handleCountryChange = (value) => {
        getData(selectedVal, value);
        setSelectedCountry(value);

    }

    const getcountryType = () => {
        get('/api/getCountry').then((res) => {
            if (res.data.length > 0) {
                setCountryType(res.data);
            }
        })
    }
    useEffect(() => {
        if (isDropDown && dropDownVal && dropDownVal[0].value == "Countries") {
            getcountryType();
            setIsCountry(true);
        }
    }, [])

    const getData = (pType = StaticDropDownVal, countryType = selectedCountry) => {
        let param = {
            type: type,
            blogType: "",
            countryType: countryType
        }
        if (type == "Podcast" || type == "blog" || type == "PerSonalities") {
            param.blogType = pType;
        }
        post("/api/getAllData/", param).then((res) => {
            if (res.success) {
                setAllData(res.data);
            } else {
                messageApi.error(MESSAGES.somethingWentWrong);
            }
            console.log(res);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        });

    }

    useEffect(() => {
        getData();
    }, [])
    return (
        <>
            {contextHolder}
            <Loader />
            {isDropDown && <Row className='rowshowData'>
                <Col span={8}> <span>{dropDownLable}</span></Col>
                <Col span={16}> <Select defaultValue={dropDownVal[0].value} style={{ width: '200px' }} id='ddval' onChange={handleChange}>
                    {isDropDown && dropDownVal.map((item) => {
                        return <Select.Option value={item.value}>{item.value} </Select.Option>

                    })
                    }
                </Select>
                </Col>
            </Row>
            }
            {isCountry &&
                <Row className='rowshowData'>
                    <Col span={8}><span>Select Country</span></Col>
                    <Col span={16}>
                        <Select onChange={handleCountryChange} style={{ width: '200px' }} defaultValue="Algeria">
                            {countryType.map((item) => {
                                return <Select.Option value={item.countryName}>{item.countryName} </Select.Option>
                            })
                            }
                        </Select>
                    </Col>
                </Row>
            }

            {allData && allData.length > 0 ?
                <Table columns={columns} dataSource={allData} /> : <> <br /><br /><br /><span>{MESSAGES.noDataAvailable}</span></>
            }
        </>

    )
}
export default ShowData;