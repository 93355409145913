import React, { useEffect, useRef, useState } from 'react';
import { InfoCircleOutlined, UserOutlined, EyeInvisibleOutlined, EyeTwoTone, ClockCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip, message, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../actions';
import { loginUserAction } from '../actions/authenticationActions';
import { useNavigate } from "react-router-dom";
import MESSAGES from '../utils/constant';
const Login = () => {
  const [passError, setPassError] = useState("success");
  const [userNameError, setUserNameError] = useState("success");
  const [isDisabled, setIsDisabled] = useState(false);
  const [userData, setUserData] = useState({ email: "", password: "" })
  const loginData = useSelector(state => state.user.login);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isActive, setIsActive] = useState(false);
  const mess = (type, val) => {
    messageApi.open({
      type: type,
      content: val,
    });
  }
  let dispatch = useDispatch();

  const ref = useRef(null);
  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" })
  }
  useEffect(() => {
    if (loginData && loginData.response) {
      if (!loginData.response.userDetails) {
        dispatch({
          type: types.RESET_STORE,
        });
        mess("error", MESSAGES.invalidLogin);
      }
      else if (loginData.response.userDetails) {
        mess("success", MESSAGES.thanksForLogin);
        navigate("/");
      } else {
        dispatch({
          type: types.RESET_STORE,
        });
        mess("error", MESSAGES.somethingWentWrong);
      }
    }
  }, [loginData])


  const onFinish = () => {
    setIsActive(true);
    if (userData.email === "") {
      setUserNameError("error")
    } else if (userData.password === "") {
      setPassError("error");
      setUserNameError("success")
    } else {
      console.log(userData);
      setIsDisabled(false);
      dispatch(loginUserAction(userData));
    }
    setTimeout(() => {
      setIsActive(false);
    }, 1000)
  };
  const inputValChange = (e) => {
    e.preventDefault();
    try {
      if (e.target.type === "password") {
        setUserData(preState => ({
          ...preState,
          password: e.target.value
        }))

      } else {
        setUserData(preState => ({
          ...preState,
          email: e.target.value
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {contextHolder}
      <Spin tip="Loading" size="large" spinning={isActive}>
        <div className="content" />
      </Spin>
      <div className="site-section" ref={ref}>
        <div className="container" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-md-5 mb-5">
              <h3 className="mb-5">Login</h3>
              <div className="bg-white">
                <div className="">
                  <div className="form-group row" style={{ textAlign: 'left' }}>
                    <div className="col-md-12">
                      <label for="c_uname" className="text-black">Username <span className="text-danger">*</span></label>
                      <Input
                        placeholder="Enter your username"
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        status={userNameError}
                        onChange={(val) => { inputValChange(val) }}
                        autoComplete="off"
                        value={userData.email}
                        suffix={
                          <Tooltip title="Extra information">
                            <InfoCircleOutlined
                              style={{
                                color: 'rgba(0,0,0,.45)',
                              }}
                            />
                          </Tooltip>
                        }
                      />


                    </div>
                  </div>
                  <div className="form-group row" style={{ textAlign: 'left' }}>
                    <div className="col-md-12">
                      <label for="c_password" className="text-black">Password <span className="text-danger">*</span></label>
                      <Input.Password
                        placeholder="input password"
                        status={passError} prefix={<ClockCircleOutlined />}
                        autoComplete="off"
                        onChange={(val) => { inputValChange(val) }}
                        value={userData.password}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <input type="submit" className="btn btn-primary btn-lg btnPrimary" disabled={isDisabled} onClick={onFinish} value="Login" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Login;