import * as types from './index';

export const registerUserAction = (user) => {
  return {
    type: types.REGISTER_USER,
    user
  }
};

export const loginUserAction = (user) => {
  return {
    type: types.LOGIN_USER,
    user
  }
};

export const getPodcatData = (user) => {
  return {
    type: types.GET_PODCAST_DATA,
    user
  }
};

export const getTopNews = (user) => {
  return {
    type: types.GET_TOPNEWS,
    user
  }
};

export const getAfricanPerData = (data) => {
  return {
    type: types.GET_AFRICANPER_DATA,
    data
  }
};
export const getBannerData = () => {
  return {
    type: types.GET_BANNER_DATA,
    
  }
};
export const getStaticData = () => {
  return {
    type: types.GET_STATIC_DATA,
    
  }
};