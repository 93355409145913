import { message, Space, Spin, Select } from 'antd';
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getStaticData } from '../actions/authenticationActions';
import BlogSetData from "./blogSetData";
import MESSAGES from '../utils/constant';
import {
    post, get
} from "../utils/api";
const BlogPost = (props) => {
    const { type } = props;
    const [messageApi, contextHolder] = message.useMessage();
    const [setData, setAllData] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [dropval, setDropVal] = useState(false);
    const [countryType, setCountryType] = useState([]);
    const staticData = useSelector(state => state.user.staticData);
    const [title1, setTitle1] = useState("");
    const [title2, setTitle2] = useState("");
    let dispatch = useDispatch();
    const ref = useRef(null);
    const setStaticData = () => {
        if (staticData.response) {
            if (type == "Africa Startups") {
                setDropVal(false);
                let tval1 = staticData.response.data.filter((val) => val._id == "653399d25167274c68666b3c")[0];
                setTitle1(tval1.Name);
                let tval2 = staticData.response.data.filter((val) => val._id == "653399e65167274c68666b3d")[0];
                setTitle2(tval2.Name);
            } else if (type == "Africa Companies") {
                setDropVal(false);
                let tval1 = staticData.response.data.filter((val) => val._id == "65339a395167274c68666b3e")[0];
                setTitle1(tval1.Name);
                let tval2 = staticData.response.data.filter((val) => val._id == "65339a4b5167274c68666b3f")[0];
                setTitle2(tval2.Name);
            }
            else if (type == "Countries") {
                let tval1 = staticData.response.data.filter((val) => val._id == "65339a825167274c68666b41")[0];
                setTitle1(tval1.Name);
                let tval2 = staticData.response.data.filter((val) => val._id == "65339a7b5167274c68666b40")[0];
                setTitle2(tval2.Name);
            }
        }
    }
    const getStaticsData = () => {
        dispatch(getStaticData());
    }
    useEffect(() => {
        if (!staticData.response) {
            getStaticsData();
        }
    }, [])

    useEffect(() => {
        setStaticData();
    }, [type])

    const getcountryType = () => {
        setIsActive(true);
        get('/api/getCountry').then((res) => {
            if (res.data.length > 0) {
                let data = res.data && res.data.map((val) => {
                    return ({ value: val.countryName, label: val.countryName })
                })
                setCountryType(data);
            }
        })
        setIsActive(false);
    }
    const handleChange = (e) => {
        let newData = setData.filter((item) => {
            if (item.countryType.trim() !== e.trim()) {
                return item
            }
        });
        setAllData(newData);
    }
    const handleChange1 = (e) => {
        if (!dropval) {
            setAllData([]);
        }
        if (e !== "Select Blog Type") {
            getData(e);
            setDropVal(true)
        }
    }
    useEffect(() => {
        if (type == "Countries") {
            getcountryType();
        }
    }, [type])
    const getData = (countryType = "") => {
        setIsActive(true);
        let param = {
            type: "blog",
            blogType: type,
            countryType: countryType
        }
        if (type == "Countries" && countryType == "") {
            getCountryFirstData();
        } else {
            post("/api/getAllData/", param).then((res) => {
                if (res.success) {
                    if (res.data.length > 0) {
                        if (dropval) {
                            setAllData(oldArray => [...res.data, ...oldArray]);
                        } else {
                            setAllData(res.data);
                        }
                    } else {
                        if (!dropval) {
                            setAllData([]);
                        }
                    }
                } else {
                    messageApi.error(MESSAGES.somethingWentWrong);
                }
                setIsActive(false);
            }).catch((error) => {
                messageApi.error(MESSAGES.somethingWentWrong);
                setIsActive(false);
            });
        }
    }
    useEffect(() => {
        ref.current.focus();
        window.scrollTo(0, 0)
    }, [type,setData,title1])

    useEffect(() => {
        getData();       
    }, [type])


    const getCountryFirstData = () => {
        setIsActive(true);
        get("/api/getAllCountryData/").then((res) => {
            if (res.success) {
                if (res.data.length > 0) {
                    setAllData(res.data);
                } else {
                    setAllData([]);
                }
            } else {
                messageApi.error(MESSAGES.somethingWentWrong);
            }
            setIsActive(false);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
            setIsActive(false);
        });
    }


    return (
        <>
            <div className="bg-light">
                <Spin tip="Loading" size="large" spinning={isActive}>
                    <div className="content" />
                </Spin>
                {contextHolder}
                <div className="row mb-5">
                    <div className="col-md-12 text-center topspace">
                        <h2 className="font-weight-bold text-black"  ref={ref}>{title1}</h2>
                        <p>{title2}</p>
                    </div>
                </div>
                <div className="ddldiv">
                    {type == "Countries" && countryType.length > 0 &&
                        <Select
                            mode="tags"
                            style={{
                                width: '97%',
                            }}
                            onSelect={handleChange1}
                            onDeselect={handleChange}
                            tokenSeparators={[',']}
                            options={countryType}
                            placeholder="Select Blog Type"
                        />
                    }
                </div>
                {setData.length > 0 ? <BlogSetData setData={setData}></BlogSetData> : <><br /><br /><span className="datanotFound">Data Not Found</span></>}
            </div>
        </>
    )
}
export default BlogPost;