import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { get } from '../utils/api';
import MESSAGES from '../utils/constant';
import { API_PREFIX } from '../utils/config';
import { getStaticData } from '../actions/authenticationActions';
import { Typography, message } from 'antd';

const AboutUs = () => {
    const ref = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const refaboutTheCompany = useRef(null);
    const refwhySubscribe = useRef(null);
    const refmeetThefounders = useRef(null);
    const refcommunity = useRef(null);
    const location = useLocation();
    let dispatch = useDispatch();
    const { Title, Paragraph, Text } = Typography;
    const type = location.state ? location.state : "aboutTheCompany";
    const staticData = useSelector(state => state.user.staticData);
    const [community, setCommunity] = useState("");
    const [meetTheF, setMeetTheF] = useState("");
    const [aboutTheCompany, setAboutTheCompany] = useState("");
    const [founder, setFounder] = useState("");

    const [allData, setData] = useState();

    const getBannerValue = () => {
        get('/api/getBanner').then((res) => {
            const val = res.data.filter((data) => !data.isHeader);
            const founder = val.filter((data) => data.ifFounder);
            setData(val);
            setFounder(founder);
        }).catch((error) => {
            messageApi.error(MESSAGES.somethingWentWrong);
        })
    }
    useEffect(() => {
        getBannerValue();
    }, [])

    const setStaticData = () => {
        if (staticData.response) {
            let commu = staticData.response.data.filter((val) => val._id == "653a46c473592d4688f1c1e2")[0];
            setCommunity(commu.Name);
            let meet = staticData.response.data.filter((val) => val._id == "653a469d73592d4688f1c1e1")[0];
            setMeetTheF(meet.Name);
            let abou = staticData.response.data.filter((val) => val._id == "653a465e73592d4688f1c1e0")[0];
            setAboutTheCompany(abou.Name);

        }
    }
    useEffect(() => {
        setStaticData();
    }, [staticData])

    const getStaticsData = () => {
        dispatch(getStaticData());
    }
    useEffect(() => {
        if (!staticData.response) {
            getStaticsData();
        }
    }, [])
    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }

    const ScrollSwitch = (scrollVal) => {
        switch (scrollVal) {
            case 'aboutTheCompany':
                ref.current?.scrollIntoView({ behavior: "smooth" })
                break;
            case 'whySubscribe':
                refwhySubscribe.current?.scrollIntoView({ behavior: "smooth" })
                break;
            case 'meetThefounders':
                refmeetThefounders.current?.scrollIntoView({ behavior: "smooth" })
                break;
            case 'community':
                refcommunity.current?.scrollIntoView({ behavior: "smooth" })
                break;
            default:
                console.log("I am default");
        }
    }

    useEffect(() => {
        ScrollSwitch(type)
    }, []);

    return (
        <>
            <div className="site-section pt-3 bg-light" ref={ref}>
                <div className="container partnershipCon">
                    <div className="row mb-5 ">
                        <div className="col-12">
                            <Title>About The Company</Title>
                        </div>
                        <div className="col-lg-12 " >
                            <Paragraph className='clsAboutUsText'>
                                {aboutTheCompany}
                            </Paragraph>
                        </div>
                        <div className="col-12 top10" ref={refwhySubscribe}>
                            <Title>Why Subscribe to Accelerate?</Title>
                        </div>
                        <div className="col-lg-12 top10">
                            {
                                allData && allData.length && allData.filter(val => !val.ifFounder).map((val) => {
                                    return (<>
                                        <div className='clsUpdateTextAndImage'>
                                            <Paragraph className='clsAboutUsTextImg'>{val.headerText}</Paragraph>
                                            <img onError={defaultIamge} src={API_PREFIX + "uploads/" + val.ImageURL} alt="Image" width={30} height={30} className="img-fluid" />
                                        </div>
                                    </>)
                                })
                            }

                        </div>
                        <div className="col-12 top10" ref={refmeetThefounders}>
                            <Title>Meet the founders!</Title>
                        </div>

                        {
                            founder && founder.length >= 2 && <>
                                <div className="col-12 imgAboutCoin">
                                    <div>
                                        <div className='imgAboutUsMain'>
                                            <img onError={defaultIamge} src={API_PREFIX + "uploads/" + founder[0].ImageURL} alt="Image" className="mx-auto img-fluid imgwidthHeightAbout" />
                                            <Text>{founder[0].headerText}</Text>
                                        </div>
                                        <Paragraph className='clsAboutUsTextHost clshostTextRight'>
                                            {founder[0].founderDes}
                                        </Paragraph>
                                    </div>

                                    <div>
                                        <div className='imgAboutUsMain'>
                                            <img onError={defaultIamge} src={API_PREFIX + "uploads/" + founder[1].ImageURL} alt="Image" className="mx-auto img-fluid imgwidthHeightAbout" />
                                            <Text>{founder[1].headerText}</Text>
                                        </div>
                                        <Paragraph className='clsAboutUsTextHost clshostTextLeft'>
                                            {founder[1].founderDes}
                                        </Paragraph>
                                    </div>


                                </div>
                            </>
                        }
                        {/* 
                        <div className="col-lg-12">
                            <Paragraph className='clsAboutUsText'>
                                {meetTheF}
                            </Paragraph>
                        </div> */}

                        <div className="col-12 top10" ref={refcommunity}>
                            <Title className="display-5 mb-3">Community </Title>
                            <Paragraph className='clsAboutUsText'>{community}</Paragraph>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs;