import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { API_PREFIX } from '../utils/config';

import { getBannerData } from '../actions/authenticationActions';
const HeaderBanner = () => {
  const [useData, setuseData] = useState();
  const bannerData = useSelector(state => state.user.bannerData);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBannerData())
  }, [])

  useEffect(() => {
    if (bannerData && bannerData.response && bannerData.response.success) {
      let data = bannerData.response.data.filter(x => x.isRunning);
      if (data.length > 0) {
        setuseData(data[0]);
      }
    }
  }, [bannerData])

  const defaultIamge = (e) => {
    //replacement of broken Image
    e.target.src = "./assest/images/1x/asset-1.png";
  }

  return (
    useData ? <div className="container  hero">
      <div className="row align-items-center text-center text-md-left">
        <div className="col-lg-4">
          <h1 className="mb-3 display-3">{useData.headerText}</h1>
        </div>
        <div className="col-lg-8">
          <img onError={defaultIamge} src={API_PREFIX + "uploads/" + useData.ImageURL} alt="Image" className="img-fluid" />
        </div>
      </div>
    </div> :
      <div className="container  hero">
        <div className="row align-items-center text-center text-md-left">
          <div className="col-lg-4">
            <h1 className="mb-3 display-3">Community, shaping the future of Africa’s Technology.</h1>
          </div>
          <div className="col-lg-8">
            <img src="./assest/images/1x/asset-1.png" alt="Image" className="img-fluid" />
          </div>
        </div>
      </div>
  )
}

export default HeaderBanner;