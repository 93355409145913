
import { post, get } from '../../utils/api';
import React, { useEffect, useState, useRef } from 'react';
import MESSAGES from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { getStaticData } from '../../actions/authenticationActions';
import {
    Button,
    Input,
    message,
    Table,
    Col, Row,
} from 'antd';

const Content = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const staticData = useSelector(state => state.user.staticData);
    const { TextArea } = Input;

    const [title, setTitle] = useState();
    const [comVal, setComVal] = useState();
    const [isEdit, setisEdit] = useState(false);
    const [editVal, setEditVal] = useState(null);
    const ref = useRef(null);
    let dispatch = useDispatch(); 

    const onChangeInput = (e) => {
        setComVal(e.currentTarget.value);
    }
    const onChangeTitle = (e) => {
        setTitle(e.currentTarget.value);
    }

    const getStaticsData = () => {
        dispatch(getStaticData());
    }
    useEffect(() => {
        if (!staticData.response) {
            getStaticsData();
        }
    }, [])
    const onEdit = (e) => {
        setTitle(e.Name)
        setComVal(e.pageName);
        setisEdit(true);
        setEditVal(e._id);     
        ref.current?.focus();
    }

    const onCancel = () => {
        setTitle("")
        setComVal("");
        setisEdit(false);
        setEditVal("");
    }

    const onFinish = async () => {
        try {
            if (title == "" || comVal == "") {
                messageApi.error("Input field can't be blank")
                return;
            }
            let param = {
                pageName: comVal,
                Name: title,
            }
            param._id = editVal;
            post("/api/updateStaticData/", param).then(() => {
                messageApi.success(MESSAGES.saveSuccessfully);
                setTitle("")
                setComVal("");
                setisEdit(false);
                setEditVal("");
                getStaticsData();
            }).catch((error) => {
                messageApi.error(MESSAGES.somethingWentWrong);
            });
        }
        catch (error) {
            messageApi.error(MESSAGES.somethingWentWrong);
        }

    }
    const columns = [
        {
            title: 'Componant(For user Ref. Only)',
            dataIndex: 'pageName',
            key: 'pageName',
            render: text => <span>{text}</span>,
        },
        {
            title: 'Title(update in UI)',
            dataIndex: 'Name',
            key: 'Name',
            render: text => <span>{text}</span>,
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (

                <Button className='clsbtnBanner' onClick={() => onEdit(record)}>Edit </Button>

            ),
        },
    ]
    return (
        <>
            {contextHolder}

            <div className='clsBannerUpdate'>
                {isEdit && <>
                    <Row className='rowinsertForm'>
                        <Col span={8}>
                            <span>Please enter componant Name(only user Ref.)</span>
                        </Col>
                        <Col span={16}>
                            <Input maxLength={55} ref={ref} required showCount value={comVal} onChange={onChangeInput} />
                        </Col>
                    </Row>
                    <Row className='rowinsertForm'>
                        <Col span={8}>
                            <span> Please enter title (update in UI)</span>
                        </Col>
                        <Col span={16}>
                            <TextArea required ref={ref} showCount value={title} rows={4}  onChange={onChangeTitle} />                          
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>

                            <>
                                <Button type='primary' className='clsbtnBanner' onClick={() => onFinish()} htmlType="submit">Submit</Button>
                                <Button type='primary' onClick={() => onCancel()} htmlType="submit">Cancel</Button>
                            </>

                        </Col>
                    </Row>
                </>
                }
            </div>

            {staticData.response && staticData.response.data &&
                <Table columns={columns} className='tblBanner' dataSource={staticData.response.data} />
            }
        </>

    );
};
export default Content;
