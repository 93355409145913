import React, { useEffect, useRef, useState } from 'react';
import { Button, Space, Radio } from 'antd';
import InsertDataForm from './insertDataForm';
import ShowData from './showData';
const AdminPartnerShip = () => {
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };
    const afterInsert=()=>{
        setValue(1);
    }
    return (
        <>
            <div className="site-section" >
                <div className="col-md-12 ">
                    <h3 className="mb-5">Partner Ship</h3>
                    <Radio.Group onChange={onChange} style={{ marginBottom: '20px' }} value={value}>
                        <Radio value={1}>Partner Ship List</Radio>
                        <Radio value={2}>Add Partner Ship</Radio>
                    </Radio.Group>
                    <br />
                    {value == 1 ? <ShowData isDropDown={false} type={"PartnerShip"}></ShowData> :
                        <InsertDataForm isDropDown={false} dropDownVal={[]} afterInsert={afterInsert} dropDownLable={""} type={"PartnerShip"} uploadType={"Upload Logo"} />}
                </div>
            </div>
        </>
    )
}
export default AdminPartnerShip;