import { Spin } from "antd"
import { useEffect, useState } from "react"

const Loader = () => {
    const [isActive, setIsActive] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsActive(false);
        }, 2000)
    }, [])

    return (
        <>
            <Spin tip="Loading" size="large" spinning={isActive}>
                <div className="content" />
            </Spin>
        </>
    )
}
export default Loader;