import * as types from '../actions';
import { combineReducers } from 'redux';

function userRegistrationReducer(state = [], action) {
  let response = action.response;
  switch (action.type) {
    case types.REGISTER_USER:
      return { loading: true, ...state };
    case types.REGISTER_USER_SUCCESS:
      return { loading: false, response };
    case types.REGISTER_USER_ERROR:
      return { loading: false, response };
    default:
      return state;
  }
}

function userLoginReducer(state = [], action) {
  const response = action.response;
  switch (action.type) {    
    case types.LOGIN_USER:
      return { loading: true, ...state };
    case types.LOGIN_USER_SUCCESS:
      return {loading: false, response };
    case types.LOGIN_USER_ERROR:
      return { loading: false, response };
      case types.RESET_STORE:
      return { loading: false};
    default:
      return state;
  }
};

function podcastReducer(state = [], action) {
  const response = action.response;
  switch (action.type) {    
    case types.GET_PODCAST_DATA:
      return { loading: true, ...state };
    case types.PODCAST_DATA_SUCCESS:
      return {loading: false, response };
    case types.PODCAST_DATA_ERROR:
      return { loading: false, response };     
    default:
      return state;
  }
};


function topNewsReducer(state = [], action) {
  const response = action.response;
  switch (action.type) {    
    case types.GET_TOPNEWS:
      return { loading: true, ...state };
    case types.TOPNEWS_DATA_SUCCESS:
      return {loading: false, response };
    case types.TOPNEWS_DATA_ERROR:
      return { loading: false, response };     
    default:
      return state;
  }
};

function africanPerReducer(state = [], action) {
  const response = action.response;
  switch (action.type) {    
    case types.GET_AFRICANPER_DATA:
      return { loading: true, ...state };
    case types.AFRICANPER_DATA_SUCCESS:
      return {loading: false, response };
    case types.AFRICANPER_DATA_ERROR:
      return { loading: false, response };     
    default:
      return state;
  }
};

function homeBannerReducer(state = [], action) {
  const response = action.response;
  switch (action.type) {    
    case types.GET_BANNER_DATA:
      return { loading: true };
    case types.PODCAST_BANNER_SUCCESS:
      return {loading: false, response };
    case types.PODCAST_BANNER_ERROR:
      return { loading: false, response };     
    default:
      return state;
  }
};

function staticDataReducer(state = [], action) {
  const response = action.response;
  switch (action.type) {    
    case types.GET_STATIC_DATA:
      return { loading: true };
    case types.PODCAST_STATIC_SUCCESS:
      return {loading: false, response };
    case types.PODCAST_STATIC_ERROR:
      return { loading: false, response };     
    default:
      return state;
  }
};

export default combineReducers({
  registration: userRegistrationReducer,
  login: userLoginReducer,
  podcast:podcastReducer,
  africanPer:africanPerReducer,
  bannerData:homeBannerReducer,
  topNews:topNewsReducer,
  staticData:staticDataReducer
})