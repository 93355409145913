import { put, call, } from 'redux-saga/effects';
import * as api from '../utils/api';

import * as types from '../actions'

export function* registerSaga(payload) {
  try {
    const response = yield call(api.post, '/user/', payload.user);
    yield put({ type: types.REGISTER_USER_SUCCESS, response});
  } catch (error) {
    if(error.response){
    yield put({ type: types.REGISTER_USER_ERROR, response:error.response.data});
    }else{
      yield put({ type: types.REGISTER_USER_ERROR, response:{error:"Error"}});
    }
  }
}

export function* loginSaga(payload) {
  try {
    const response = yield call(api.post,'/api/loginuser/', payload.user);
    yield call(api.setAuthToken, response.token);
    yield put({ type: types.LOGIN_USER_SUCCESS, response });   
  } catch (error) {
    yield put({ type: types.LOGIN_USER_ERROR, error })
  }
}

export function* getPodcastDataSaga(payload) {
  try {  
    const response = yield call(api.post,'/api/getTop10Data/', payload.user);
    yield put({ type: types.PODCAST_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.PODCAST_DATA_ERROR, error })
  }
}

export function* getTopNewsDataSaga(payload) {
  try {  
    const response = yield call(api.post,'/api/getTop10Data/', payload.user);
    yield put({ type: types.TOPNEWS_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.TOPNEWS_DATA_ERROR, error })
  }
}

export function* getAfricanSaga(payload) {
  try {  
    const response = yield call(api.post,'/api/getTop10Data/', payload.data);
    yield put({ type: types.AFRICANPER_DATA_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.AFRICANPER_DATA_ERROR, error })
  }
}

export function* getHomeBannerSaga(payload) {
  try {  
    const response = yield call(api.get,'/api/getBanner/');
    yield put({ type: types.PODCAST_BANNER_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.PODCAST_BANNER_ERROR, error })
  }
}
export function* getStaticDataSaga() {
  try {      
    const response = yield call(api.get,'/api/getStaticValue/');
    yield put({ type: types.PODCAST_STATIC_SUCCESS, response });
  } catch (error) {
    yield put({ type: types.PODCAST_STATIC_ERROR, error })
  }
}