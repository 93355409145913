
import React, { useState } from 'react';
import MESSAGES from '../../utils/constant';
import InsertDataForm from './insertDataForm';
import { Radio } from 'antd';
import ShowData from './showData';
const AdminBlog = () => {
    const [value, setValue] = useState(1);
    const blogType = [ { value: "Africa Startups" },{ value: "Countries" }, { value: "Africa Companies" }]
    const onChange = (e) => {
        setValue(e.target.value);
    };
    const afterInsert = () => {
        setValue(1);
    }
    return (
        <>
            <div className="site-section" >
                <div className="col-md-12 ">
                    <h3 className="mb-5">Blog</h3>
                    <Radio.Group onChange={onChange} style={{ marginBottom: '20px' }} value={value}>
                        <Radio value={1}>Blog List</Radio>
                        <Radio value={2}>Add Blog</Radio>
                    </Radio.Group>
                    <br />
                    {/* <InsertDataForm isDropDown={true} dropDownVal={blogType} dropDownLable={MESSAGES.selectBlogType} type={"blog"} uploadType={"Upload image"} /> */}

                    {value == 1 ? <ShowData isDropDown={true} dropDownVal={blogType} dropDownLable={MESSAGES.selectBlogType} type={"blog"}></ShowData> :
                        <InsertDataForm isDropDown={true} dropDownVal={blogType} afterInsert={afterInsert} dropDownLable={MESSAGES.selectBlogType} type={"blog"} uploadType={"Upload Logo"} />
                    }

                </div>
            </div>
        </>
    )
}
export default AdminBlog;