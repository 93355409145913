import { useEffect ,useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { API_PREFIX } from '../utils/config';
import { NavLink } from "react-router-dom";
import { getAfricanPerData } from '../actions/authenticationActions';

const BehindTheMap = () => {
  const afrPer = useSelector(state => state.user.africanPer);
  const staticData = useSelector(state => state.user.staticData);
  const [title1, setTitle1] = useState("")
  let dispatch = useDispatch();
  const getDataByType = () => {
    const param = { type: "PerSonalities", blogType: "top6" }
    dispatch(getAfricanPerData(param));
  }
  const setStaticData = () => {
    if (staticData.response) {
      let tval1 = staticData.response.data.filter((val) => val._id == "653396335167274c68666b33")[0];
      setTitle1(tval1.Name);
    }
  }
  useEffect(() => {
    setStaticData();
  }, [staticData])

  useEffect(() => {
    if (!afrPer.response) {
      getDataByType();
    }
  }, [])

  const defaultIamge = (e) => {
    //replacement of broken Image
    e.target.src = "./assest/images/default_image.jpg";
  }

  const returnSubStr = (str, char) => {
    let subSTR = str;
    if (str.length > char) {
      subSTR = str.slice(0, char);     
    }
    return subSTR + " ..";
  }

  return (
    afrPer.response && afrPer.response.data.length > 0 ?
      <div className="site-section paddingTopcls paddingBottam">
        <div className="container" data-aos="fade-up">
          <div className="row mb-5 topBehind">
            <div className="col-md-12 text-center">
              <h2 className="font-weight-bold text-black">{title1}</h2>
            </div>
          </div>
          <div className="row">
            {afrPer.response.data.map((item) => {
              return (
                <>
                  <div className="col-md-6 col-lg-4 mb-5 mb-lg-5 reducePadding">
                    <div className="team-member">
                      <img onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} alt="Image" className="img-fluid" />
                      <div className="text">
                        <h2 className="mb-2 font-weight-light h4">{returnSubStr(item.title, 23)}</h2>                        
                        <p className="mb-4">{returnSubStr(item.description, 150)}</p>
                        <NavLink type="link"
                          to={`/podcastsinglepost?type=${item._id}`}
                          state={item}
                        >Keep Reading..</NavLink>
                        <div>
                          <audio id="player2" preload="none" controls style={{ maxWidth: '100%' }}>
                            <source src={API_PREFIX + "uploads/" + item.audiofileName} type="audio/mp3" />
                          </audio>
                        </div>
                      </div>

                    </div>
                  </div>

                </>
              )
            })
            }
          </div>
        </div>
      </div> : ""

  )
}

export default BehindTheMap;