
import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isValidEmail } from '../utils/commonUtils';
import { post } from '../utils/api';
import { message } from 'antd';
import { NavLink } from 'react-router-dom';
import MESSAGES from '../utils/constant';
const Footer = () => {
  const [email, setEmail] = useState("");
  const [isError, setIsError] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const ref = useRef(null);
  const [title1, setTitle1] = useState("");

  const staticData = useSelector(state => state.user.staticData);
  const setStaticData = () => {
    if (staticData.response) {
      let tval1 = staticData.response.data.filter((val) => val._id == "653398745167274c68666b37")[0];
      setTitle1(tval1.Name);
    }
  }
  useEffect(() => {
    setStaticData();
  }, [staticData])

  const inputValChange = (e) => {
    e.preventDefault();
    try {
      setEmail(e.target.value);
    } catch (error) {
      console.log(error)
    }
  }
  const mess = (type, val) => {
    messageApi.open({
      type: type,
      content: val,
    });
  }
  const onfinish = () => {
    const val = isValidEmail(email);
    setIsError(!val);
    if (val) {
      const data = {
        email: email
      }
      post('/api/subcribe/', data).then((res) => {
        if (res.success) {
          mess('success', MESSAGES.thanksForSubcribe);
        } else {
          mess('error', MESSAGES.somethingWentWrong);
        }
      }).catch((error) => {
        const val = error.response;
        if (val && val.data && val.data.error.code == 11000) {
          mess('error', MESSAGES.emailAlreadySubcribed);
        } else {
          mess('error', MESSAGES.somethingWentWrong);
        }
      });
    }
    ref.current.value = "";
  }

  return (
    <>
      {contextHolder}
      <footer className="site-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 mx-auto">
              <h3>About Us</h3>
              <ul className="list-unstyled">
                <li><NavLink
                  type="link"
                  to={"/about"}
                  state={"aboutTheCompany"}>About The Company</NavLink></li>

                <li><NavLink type="link"
                  to={"/about"}
                  state={"whySubscribe"}>Why Subscribe to Accelerate</NavLink></li>
                <li><NavLink type="link"
                  to={"/about"}
                  state={"meetThefounders"}>Meet the founders</NavLink></li>
                <li><NavLink type="link"
                  to={"/about"}
                  state={"community"}>Community</NavLink></li>
                <li><NavLink to="/contactus">Contact</NavLink></li>
              </ul>
            </div>
            <div className="col-lg-3 mx-auto">
              <h3>Navigation</h3>
              <ul className="list-unstyled">
                <li><NavLink to="/allpodcast">Podcasts</NavLink></li>
                <li key="btnAfricaStart"><NavLink to="/africastartups">Africa Startups</NavLink></li>
                <li key="btnAfricaCompanies"><NavLink to="/africacompanies">Africa Companies</NavLink></li>
                <li key="btnCountries"><NavLink to="/countries">Countries</NavLink></li>
              </ul>
            </div>
            <div className="col-lg-4">
              <h3>Subscribe</h3>
              <p>{title1}</p>
              <div className="form-subscribe">
                {isError ? <span className='spnError'>{MESSAGES.enterValidEmail}</span> : ""}
                <input type="email" ref={ref} onChange={(val) => { inputValChange(val) }} className="form-control mb-3" placeholder="Enter Email" />
                <input type="submit" onClick={onfinish} className="btn btn-info" value="Subscribe" />
              </div>
            </div>
          </div>
          <div className="row pt-5 mt-5 text-center">
            <div className="col-md-12">
              <p>
                Copyright &copy;{new Date().getFullYear()} All rights reserved | design and developed <i className="icon-heart-o" aria-hidden="true"></i> by <a href="https://www.linkedin.com/in/aashishtyagig/" target="_blank" rel="noreferrer">Aashish Tyagi</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer;