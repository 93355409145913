import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import configStore from './store/configureStore';
import reportWebVitals from './reportWebVitals';
import { render } from "react-dom";  
const root = ReactDOM.createRoot(document.getElementById('root'));
render(
  <React.StrictMode>
    <Provider store={configStore()}>
      <App />
    </Provider>
  </React.StrictMode>,
   document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
