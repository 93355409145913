import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../componant/login";
import AboutUs from "../componant/aboutUs";
import ContactUs from "../componant/contactUs";
import Dashboard from "../componant/dashboard";
import PartnerShip from '../componant/partnerShip';
import BlogPost from '../componant/blogPost';
import BehindTheMap from '../componant/behindTheMap';
import SinglePost from '../componant/singlePostBlog';
import AdminPartnerShip from "../componant/Admin/adminPartnership";
import AdminBlog from "../componant/Admin/blog";
import AddPodcast from "../componant/Admin/addPodcast";
import AddPerSonalities from "../componant/Admin/personalities";
import SubcribersList from "../componant/Admin/allSubcriber";
import PodcastSinglePost from "../componant/podcastSinglePost";
import AllPodcast from "../componant/allPodcast";
import HeaderBanner from "../componant/Admin/banner";
import AllAfricanPersonalities from "../componant/allAfricanPersonalities";
import AfricanNews from "../componant/africanNews";
import InsertDataForm from "../componant/Admin/insertDataForm";
import Content from "../componant/Admin/content";
import UploadFounderImage from "../componant/Admin/uploadFounderImage";
import AllContact from "../componant/Admin/contactUsPage";
// import AboutUsAdmin from "../componant/Admin/aboutUs";

const AppRoutes = () => {
  return (<Routes>
    <Route path='/' element={<Dashboard />} />
    <Route path='/about' element={<AboutUs />} />
    <Route path='/contactus' element={<ContactUs />} />
    <Route path='/login' element={<Login />} />
    <Route path='/partnership' element={<PartnerShip />} />
    <Route path='/InsertData' element={<InsertDataForm />} />

    <Route path='/africastartups' element={<BlogPost type="Africa Startups" />} />
    <Route path='/africacompanies' element={<BlogPost type="Africa Companies" />} />
    <Route path='/countries' element={<BlogPost type="Countries" />} />

    <Route path='/africanpersonalities' element={<BehindTheMap />} />
    <Route path='/allAfricanPersonalities' element={<AllAfricanPersonalities />}></Route>
    <Route path='/singlepost' element={<SinglePost />} />
    <Route path='/adminPartnerShip' element={<AdminPartnerShip />} />
    <Route path='/adminBlog' element={<AdminBlog />} />
    <Route path='/addPodcast' element={<AddPodcast />} />
    <Route path='/addPerSonalities' element={<AddPerSonalities />} />
    <Route path='/subcribersList' element={<SubcribersList />} />
    <Route path='/podcastsinglepost' element={<PodcastSinglePost />} />
    <Route path='/allpodcast' element={<AllPodcast />} />
    <Route path='/headerBanner' element={<HeaderBanner />}></Route>
    <Route path='/africanNews' element={<AfricanNews />}></Route>
    <Route path='/content' element={<Content />}></Route>
    <Route path='/uploadFounderImage' element={<UploadFounderImage />}></Route>
    <Route path='/allContact' element={<AllContact />}></Route>
    {/* <Route path='/AboutUsAdmin' element={<AboutUsAdmin />}></Route> */}

  </Routes>)
};



export default AppRoutes;