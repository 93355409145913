import { useEffect, useState } from "react";
import { get, post } from '../utils/api';
import PodcastData from "./podcastData";
import { message, Spin, Select } from 'antd';

const AllPodcast = () => {
  const [podcastType, setPodcastType] = useState([]);
  const [getdata, setGetData] = useState([]);
  const [podcastDataType, setpodcastDataType] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [alldata, setAlldata] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const getPodcastType = () => {
    get('/api/getPodcastType').then((res) => {
      let data = res.data && res.data.filter((val) => val.podcastTypes).map((val) => {
        return ({ value: val.podcastTypes, label: val.podcastTypes })
      })
      setPodcastType(data);
    })
  }

  const getDataByType = (val) => {
    const param = { type: "Podcast", blogType: val }
    setIsActive(true);
    post('/api/getAllData/', param).then((res) => {
      if (res.success && res.data.length > 0) {
        setGetData(oldArray => [...res.data, ...oldArray]);
      }
      setIsActive(false);
    }).catch((error) => {
      setIsActive(false);
      console.error("Something Went Wrong");
    })
  }

  const get50Podcast = () => {
    const param = { type: "Podcast", limit: true }
    setIsActive(true);
    post('/api/getTop10Data/', param).then((res) => {
      if (res.success && res.data.length > 0) {
        setAlldata(res.data)
      } else {
        setAlldata([])
      }
      setIsActive(false);
    }).catch((error) => {
      console.error("Something Went Wrong");
      setIsActive(false);
    })
  }
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  useEffect(() => {
    getPodcastType();
    get50Podcast();
  }, []);

  const handleChange = (e) => {
    let newData = getdata.filter((item) => {
      if (item.blogType !== e) {
        return item
      }
    });
    setGetData(newData);
  }
  const handleChange1 = (e) => {
    if (e !== "Select podcast") {
      getDataByType(e);
      setpodcastDataType(e);
    }
  }

  const HtmlData = () => {
    if (podcastDataType != "") {
      return <>{getdata.length > 0 ? <PodcastData title={''} data={getdata} dynamicClass="top61px" type="podcasts"></PodcastData>
        : <span className="top10pix">No Data Available</span>
      }</>
    } else {
      return alldata.length > 0 ?
        <PodcastData title={''} data={alldata} dynamicClass="top61px"  type="podcasts"></PodcastData>
        : <span className="top10pix">No Data Available</span>
    }

  }
  return (
    <div className="bg-light">
      <Spin tip="Loading" size="large" spinning={isActive}>
        <div className="content" />
      </Spin>
      {contextHolder}
      <div className="row mb-5" >
        <div className="col-md-12 text-center topspace">
          <h2 className="font-weight-bold text-black">All Episodes</h2>
          <p>Listen to African thought leaders News</p>
        </div>
      </div>
      <div className="ddldiv">
        {podcastType.length > 0 &&
          <> <Select
            mode="tags"
            style={{
              width: '89%',
            }}
            // onChange={handleChange}
            onSelect={handleChange1}
            onDeselect={handleChange}
            tokenSeparators={[',']}
            options={podcastType}
            placeholder="Select Podcast Type"
          /></>
        }
      </div>     
        {
          HtmlData()
        }    
    </div>
  )
}
export default AllPodcast;