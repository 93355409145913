import { API_PREFIX } from '../utils/config';
import { useLayoutEffect } from 'react';
import { NavLink } from "react-router-dom";
import { RiseOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import loadjs from "loadjs";
const PartnerShipData = (props) => {
    const { getData } = props;
    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }

    useLayoutEffect(() => {
        loadjs(['/assest/css/owl.carousel.min.css', '/assest/js/owl.carousel.min.js', '/assest/js/main.js']);

    }, [])

       return (<>
        <div className='site-section bg-light block-13 whitebackground paddingTopcls' >
            <div className="container whitebackground">                              
                    <div className="podcastDiv1">
                        {
                            getData.map((item) => {
                                return (<div className="divnewPartner">                                 
                                        <NavLink type="link"
                                            to={`/singlepost?type=${item._id}`}
                                            state={item}
                                        >
                                            <RiseOutlined className='clsicone' />
                                            <img onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} alt="Image"  />
                                        </NavLink>
                                   
                                </div>
                                )
                            })
                        }
                    </div>              
            </div>
        </div>
    </>)
}

export default PartnerShipData;