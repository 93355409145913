const HtmlData = (props) => {
const {data}=props;
  
    return (
      <div
        dangerouslySetInnerHTML={{__html: data}}
      />
    );
  }
  
  export default HtmlData;