import { NavLink } from "react-router-dom";
import React, { useState, useEffect} from 'react';
import * as types from '../actions';
import { useSelector, useDispatch } from 'react-redux';
const Header = () => {  
  const [isLogin, setLogin] = useState(false)
  const loginData = useSelector(state => state.user.login);
  let dispatch = useDispatch();
  const Logout = () => {
    // sessionStorage.clear();
    localStorage.clear();
    dispatch({ type: types.RESET_STORE });
    setLogin(false);
  }
  useEffect(() => {
    if (loginData && loginData.response) {
      if (loginData.response.userDetails) {
        // sessionStorage.setItem("token", loginData.response.token);
        // sessionStorage.setItem("isLogin", true);
        localStorage.setItem("userDetails", JSON.stringify(loginData.response.userDetails))
        localStorage.setItem("token", loginData.response.token);
        localStorage.setItem("isLogin", true);
        setLogin(true);
      }
    }
  }, [loginData])

 
  useEffect(() => {
    const val = localStorage.getItem("isLogin");
    if (val) {
      setLogin(val);
    }
  }, [])

  return (
    <>
      <div className="site-mobile-menu">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>
      <header className="site-navbar py-4" role="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-4">
              <NavLink to="/">
                <img src="./newLogo.png" style={{ width: "100%", height: "100%" }} alt="Image" />
              </NavLink>
            </div>
            <div className="col-8">
              <nav className="site-navigation position-relative text-right text-md-right" role="navigation">
                <div className="d-block d-lg-none ml-md-0 mr-auto"><a href="#" className="site-menu-toggle js-menu-toggle text-black"><span className="icon-menu h3"></span></a></div>
                <ul key="menuItem" className="site-menu js-clone-nav d-none d-lg-block">
                  <li key="btnHome">
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li className="has-children" key="btnAbout">
                    <NavLink to="/about">About</NavLink>
                    <ul className="dropdown arrow-top">
                      <li key="btnAbout2">
                        <NavLink to="/about">About</NavLink>
                      </li>
                      <li key="btnPartnerShip">
                        <NavLink to="/partnership">Partnership</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li key="btnEpisodes" className="has-children">
                    <NavLink to="/">Episodes</NavLink>
                    <ul className="dropdown arrow-top">
                      <li key="btnAllEpisodes">
                        <NavLink to="/allpodcast">All Episodes</NavLink>
                      </li>
                      <li key="btnAfricanPer"><NavLink to="/allAfricanPersonalities">African Personalities</NavLink></li>
                      <li key="btnAfricanNews"><NavLink to="/africanNews">African News</NavLink></li>
                    </ul>
                  </li>
                  <li key="btnExplore" className="has-children">
                    <NavLink to="/africain30mins">Explore</NavLink>
                    <ul className="dropdown arrow-top">
                      <li key="btnAfricaStart"><NavLink to="/africastartups">Africa Startups</NavLink></li>
                      <li key="btnAfricaCompanies"><NavLink to="/africacompanies">Africa Companies</NavLink></li>
                      <li key="btnCountries"><NavLink to="/countries">Countries</NavLink></li>
                    </ul>
                  </li>
                  {isLogin &&
                    <>
                      <li key="btnAdmin" className="has-children">
                        <NavLink to="/africain30mins">Admin</NavLink>
                        <ul className="dropdown arrow-top">
                          <li key="btnpodcast"><NavLink to="/addPodcast">Podcast</NavLink></li>
                          <li key="btnAfricaPer"> <NavLink to="/addPerSonalities">African Personalities</NavLink></li>
                          <li key="btnPartner"> <NavLink to="/adminPartnerShip">Partnership</NavLink></li>
                          <li key="btnBlog"> <NavLink to="/adminBlog">Blog</NavLink></li>
                          <li key="btnAllSubcriber"> <NavLink to="/subcribersList">All Subcriber</NavLink></li>
                          <li key="btnBanner"> <NavLink to="/headerBanner">Banner</NavLink></li>
                          <li key="btnContent"> <NavLink to="/content">Content</NavLink></li>
                          <li key="founderImage"> <NavLink to="/uploadFounderImage">Upload Founder Image</NavLink></li>
                          <li key="btnBanner"> <NavLink to="/AllContact">All Contact</NavLink></li>
                        </ul>
                      </li>
                      <li key="btnLogout"><NavLink onClick={Logout}>Logout</NavLink></li>
                    </>
                  }
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
export default Header;