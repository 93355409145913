
import { useState, useEffect } from 'react';
import { Divider, Typography } from 'antd';

import { useSelector } from 'react-redux';
const { Title, Paragraph, Text, Link } = Typography;
const HomeSection = () => {
    const [title1, setTitle1] = useState("");
    const [title2, setTitle2] = useState("");

    const staticData = useSelector(state => state.user.staticData);
    const setStaticData = () => {
        if (staticData.response) {
            let tval1 = staticData.response.data.filter((val) => val._id == "653398195167274c68666b35")[0];
            setTitle1(tval1.Name);
            let tval2 = staticData.response.data.filter((val) => val._id == "6533984b5167274c68666b36")[0];
            setTitle2(tval2.Name);
        }
    }
    useEffect(() => {
        setStaticData();
    }, [staticData])

    return (
        <>
            <div className="site-section bg-light block-13">
                <div className='bg-white'>
                    <div className="container hero bg-white">
                        <div className="row align-items-center text-center text-md-left">
                            <div className="col-lg-12">
                                <div className='clsDivSection  '>
                                    <Paragraph className='clsHomeSection'>{title1}</Paragraph>
                                    <Text className='clsMoses'>{title2}</Text>
                                    <div className='clsSocialDiv'>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Apple.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Breaker.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Castbox.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Google-Podcasts.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Overcast.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Pocket-Casts.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Radio-Public.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/RSS.jpg'></img>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            <img className='clsImageSocial' alt="Image" type="image/jpg" src='./socialNetwork/Spotify.jpg'></img>
                                        </a>

                                    </div>

                                    <div className='clsSocialDivtext'>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            Spotify <span>|</span>
                                        </a>
                                        <a className="anchor" href={"https://podcastsconnect.apple.com/my-podcasts/show/africelerator/60000d64-fe9b-43e9-9c90-8f022229bd76"} target="_blank" rel="noreferrer">
                                            Apple  <span>|</span>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            YouTube  <span>|</span>
                                        </a>
                                        <a className="anchor" href={""} target="_blank" rel="noreferrer">
                                            Feeds  <span>|</span>
                                        </a>
                                        <a className="anchor" href={"https://dashboard.rss.com/podcasts/africelerator/"} target="_blank" rel="noreferrer">
                                            RSS  <span>|</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
export default HomeSection;