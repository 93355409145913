import { useLayoutEffect, useState, useEffect } from "react";
import { API_PREFIX } from '../utils/config';
import { NavLink } from "react-router-dom";
import HtmlData from "./showHtmlData";
import LikeItem from "./likeItem";
import loadjs from "loadjs";
import moment from "moment/moment";
const Podcast = (props) => {
  const { title, type, data, topClass = "" } = props;

  useLayoutEffect(() => {
    loadjs(['/assest/css/owl.carousel.min.css', '/assest/js/owl.carousel.min.js', '/assest/js/main.js']);
  }, [])

  const defaultIamge = (e) => {
    //replacement of broken Image
    e.target.src = "./assest/images/default_image.jpg";
  }

  const returnSubStr = (str, char) => {
    let subSTR = str;
    if (str.length > char) {
      subSTR = str.slice(0, char);
    }
    return subSTR + " ..";
  }
  return (
    <div className={`site-section bg-light block-13 paddingTopcls`}>
      <div className="container">
        {(type == "news" || type == "podcasts") &&
          <div>
            <div className={`row mb-5 ${topClass}`}>
              <div className="col-md-12 text-center">
                <h2 className="font-weight-bold text-black">{title}</h2>
              </div>
            </div>
            <div className="nonloop-block-13 owl-carousel ">
              {
                data.map((item) => {
                  const dateNew = moment(item.updatedAt).format('DD-MMM-YYYY');
                  let author = ""
                  if (item.author) {
                    author = item.author;
                  }
                  return (<div className="text-center p-3 p-md-5 bg-white">
                    <div className="mb-4">
                      <LikeItem id={item._id} likebuttonClass="likebutton"></LikeItem>
                      {/* <span className="spnDatePod">{dateNew}</span> */}
                      <img alt="Image" onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} className=" mx-auto img-fluid clsImageSize" />
                      <span className="spnAuthor">{"Author:" + author}</span>
                    </div>

                    <div className="leftAligh">
                      <span className="h5">{returnSubStr(item.title, 23)}</span>
                      <p className="para">
                        {<HtmlData data={returnSubStr(item.description, 90)}></HtmlData>}
                      </p>
                      <NavLink type="link"
                          to={`/podcastsinglepost?type=${item._id}`}
                          state={item}
                        >Keep Reading..</NavLink>                     
                    </div>
                    <audio id="player2" preload="none" controls style={{ maxWidth: '100%' }}>
                      <source src={API_PREFIX + "uploads/" + item.audiofileName} type="audio/mp3" />
                    </audio>
                  </div>)
                })
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}
export default Podcast;