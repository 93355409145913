import { useLocation } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import { API_PREFIX } from '../utils/config';
import HtmlData from './showHtmlData';
import { post } from '../utils/api';
import moment from "moment/moment";
const PodcastSinglePost = (props) => {
    const location = useLocation();
    const [data, setData] = useState('');
    const ref = useRef(null);

    const defaultIamge = (e) => {
        e.target.src = "./assest/images/default_image.jpg";
    }
    const scrollToBottom = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
    }
    const getDateAndAuthor = (data) => {
        const dateNew = moment(data.updatedAt).format('DD-MMM-YYYY')
        let author = "";
        if (data.author) {
            author = data.author;
        }
        return (
            <><><span className='spndateNew'>{dateNew}</span><span>{"Author:" + author}</span></></>
        )
    }
    useEffect(() => {
        scrollToBottom()
    }, []);

    useEffect(() => {
        if (location && location.state) {
            setData(location.state)
        } else {
            const search = location.search;
            const params = new URLSearchParams(search)
            const ID = params.get('type');
            if (ID) {
                getData(ID);
            }
        }
    }, [location])
    const getData = (id) => {
        const param = { id: id }
        post('/api/getDataByID/', param).then((res) => {
            if (res.success && res.data) {
                setData(res.data)
            } else {
                setData([])
            }
        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }

    return (
        <>
            <div class="container mt-4" ref={ref}>
                <div class="row">
                    <div class="col-lg-12">
                        <div style={{ marginBottom: '5%' }}>
                            {data && data.title ? <>
                                <div className="mb-4">
                                    <img alt="Image" onError={defaultIamge} src={API_PREFIX + "uploads/" + data.logoImage} className=" mx-auto img-fluid singleBlogImage" />
                                </div>
                                {getDateAndAuthor(data)}
                                <div class="card-body">
                                    <h5 class="card-title">{data.title}</h5>
                                    <audio id="player2" preload="none" controls style={{ maxWidth: '100%', width: '100%' }}>
                                        <source src={API_PREFIX + "uploads/" + data.audiofileName} type="audio/mp3" />
                                    </audio>
                                    <p class="card-text">{<HtmlData data={data.description}></HtmlData>}</p>
                                </div>  </> : <h2 class="card-title clsCardAlign">Data Not Found</h2>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PodcastSinglePost;