
import './App.css';
import Footer from './componant/footer';
import Header from './componant/header';
import AppRoutes from './utils/routes';
import Search from './componant/search';

import { BrowserRouter as Router } from "react-router-dom";
function App() {
  return (
    <Router>
      <div className="App">
        <div class="site-wrap">
          <Header />
          <Search/>
          <AppRoutes />
          <Footer />
        </div>
      </div>
    </Router>
  );
}
export default App;
