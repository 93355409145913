import { useLocation } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
import HtmlData from './showHtmlData';
import { API_PREFIX } from '../utils/config';
import { post } from '../utils/api';

const SinglePost = () => {
    const location = useLocation();
    const ref = useRef(null);
    const [ data, setData ] = useState();
    const defaultIamge = (e) => {
        e.target.src = "./assest/images/default_image.jpg";
    }
    const scrollToBottom = () => {
        ref.current?.scrollIntoView({ behavior: "smooth" })
    }
    useEffect(() => {
        if (location && location.state) {
            setData(location.state)
        } else {
            const search = location.search;
            const params = new URLSearchParams(search)
            const ID = params.get('type');
            if (ID) {
                getData(ID);
            }
        }
    }, [])
    const getData = (id) => {
        const param = { id: id }
        post('/api/getDataByID/', param).then((res) => {
            if (res.success && res.data) {
                  setData(res.data)
            } else {
                 setData([])
            }
        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }



    useEffect(() => {
        scrollToBottom()
    }, []);
    return (
        <>

            <div class="container mt-4" ref={ref}>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card clsBlog" >
                            {data && data.title ? <>
                                <h2 class="card-title clsCardAlign">{data.title}</h2>
                                {data.type == "PartnerShip" ?
                                    <img onError={defaultIamge} src={API_PREFIX + "uploads/" + data.logoImage} class="card-img-top partnershipImage" alt="Blog Post Image" /> :
                                    <img onError={defaultIamge} src={API_PREFIX + "uploads/" + data.logoImage} class="card-img-top" alt="Blog Post Image" />
                                }
                                <div class="card-body">
                                    <p class="card-text">{<HtmlData data={data.description}></HtmlData>}</p>
                                    {data.type == "PartnerShip" && <a className="anchor" href={data.partnershipURL} target="_blank" rel="noreferrer">Keep Reading..</a>}
                                </div>
                            </> : <h2 class="card-title clsCardAlign">Data Not Found</h2>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SinglePost;