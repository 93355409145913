import React, { useEffect, useState, useLayoutEffect } from 'react';
import loadjs from "loadjs";
import { post } from '../utils/api';
import PartnerShipData from './partnershipData';
import { getStaticData } from '../actions/authenticationActions';
import { useDispatch, useSelector } from 'react-redux';
const PartnerShip = () => {
    const [getData, setGetData] = useState([]);
    const staticData = useSelector(state => state.user.staticData);
    const [title1, setTitle1] = useState("")
    const [title2, setTitle2] = useState("")
    let dispatch = useDispatch();
    const setStaticData = () => {
        if (staticData.response) {
            let tval1 = staticData.response.data.filter((val) => val._id == "653399095167274c68666b38")[0];
            setTitle1(tval1.Name);
            let tval2 = staticData.response.data.filter((val) => val._id == "653399245167274c68666b39")[0];
            setTitle2(tval2.Name);
        }
    }
    const getStaticsData = () => {
        dispatch(getStaticData());
    }
    useEffect(() => {
        if (!staticData.response) {
            getStaticsData();
        }
    }, [])
    useEffect(() => {
        setStaticData();
    }, [staticData])

    useLayoutEffect(() => {
        loadjs(['/assest/css/owl.carousel.min.css', '/assest/js/owl.carousel.min.js', '/assest/js/main.js']);
    }, [])

    const getDataByType = () => {
        const param = { type: "PartnerShip", blogType: "PartnerShip" }
        post('/api/getAllData/', param).then((res) => {
            if (res.success && res.data.length > 0) {
                setGetData(res.data)
            } else {
                setGetData([])
            }

        }).catch((error) => {
            console.error("Something Went Wrong");
        })
    }
    useEffect(() => {
        getDataByType();
    }, [])
    return (
        <>
            <div className="site-section pt-3 bg-light">
                <div className="container">
                    <div className="row mb-5 topspace">
                        <div className="col-12">
                            <h2 className="display-5 mb-3 text-black">{title1}</h2>
                        </div>
                        <div className="col-lg-12">
                            <p className="partnershipP">{title2}</p>
                        </div>
                    </div>
                    {
                        getData.length > 0 && <PartnerShipData getData={getData}></PartnerShipData>
                    }
                </div>
            </div>
        </>
    );
}

export default PartnerShip;