import { API_PREFIX } from '../utils/config';
import { NavLink } from "react-router-dom";
import HtmlData from "./showHtmlData";
const BlogSetData = (props) => {
    const { setData } = props;
    
    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }

    const returnSubStr = (str, char) => {
        let subSTR = str;
        if (str.length > char) {
          subSTR = str.slice(0, char);     
        }
        return subSTR + " ..";
    }
    return (<>
        <div class="row blogMargin">
            {setData.map((item) => {
                return <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="card mb-5 shadow-sm ">
                        <img onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} class="img-fluid imgHeightWidth" />
                        <div class="card-body">
                            <div class="card-title clsblogPostTitle">
                                <h2>{returnSubStr(item.title, 23)}</h2>
                            </div>
                            <div class="card-text clsblogPostText" >
                                    {<HtmlData data={returnSubStr(item.description, 200)}></HtmlData>}
                            </div>
                            <NavLink className="anchor"
                                to={`/singlepost?type=${item._id}`}
                                state={item}
                            >Read More..</NavLink>
                        </div>
                    </div>
                </div>
            })            
            }

        </div>
    </>)
}
export default BlogSetData;