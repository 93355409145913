import { useState, useEffect } from 'react';
import { Input, Modal, FloatButton, Col, Row, Spin } from 'antd';
import { post } from '../utils/api';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { API_PREFIX } from '../utils/config';
import moment from "moment/moment";
const Search = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [searchData, setSearchData] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const openModel = () => {
        setIsModalOpen(true);
    }
    const defaultIamge = (e) => {
        //replacement of broken Image
        e.target.src = "./assest/images/default_image.jpg";
    }

    const getSearchData = () => {
        const data = {
            value: searchVal
        }
        post('/api/getSearchData/', data).then((res) => {
            setSearchData(res.data);
            setIsActive(false);
        }).catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        const getData = setTimeout(() => {
            if (searchVal != "" && searchVal.length > 3) {
                getSearchData();
            } else {
                setSearchData([]);
                setIsActive(false);
            }
        }, 2000)
        return () => clearTimeout(getData)
    }, [searchVal])

    useEffect(() => {
        if (searchVal != "" && searchVal.length > 3) {
            setIsActive(true);
        }
    }, [searchVal])

    const clickOnSearch = (item) => {
        setIsModalOpen(false);
        const itemType = item.type;
        if (itemType == "Podcast" || itemType == "PerSonalities") {
            navigate('/podcastsinglepost',
                {
                    state: item
                });
        } else if (itemType == "PartnerShip" || itemType == "blog") {
            navigate('/singlepost',
                {
                    state: item
                });
        }
    }

    return (<>
        <Modal open={isModalOpen} width={1000} className='clsModel' style={{
            height: 600,
        }} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <Input placeholder="Enter mininum 4 char.." onChange={(event) => setSearchVal(event.target.value)}></Input>
            <div className='searchData'>
                <Spin tip="Loading" size="large" className='spinSearch' spinning={isActive}>
                    <div className="content" />
                </Spin>
                {
                    searchData.length > 0 &&
                    searchData.map((item) => {
                        let blogType = "";
                        let countryType = "";
                        let author = "";
                        const dateNew = moment(item.updatedAt).format('DD-MMM-YYYY');;
                        if (item.blogType && item.blogType != "") {
                            blogType = item.blogType;
                        }
                        if (item.countryType && item.countryType != "") {
                            countryType = item.countryType;
                        }
                        if (item.author && item.author != "") {
                            author = "  Author: " + item.author;
                        }
                        return (
                            <div className='searchDataCard' onClick={() => clickOnSearch(item)}>
                                <Row>
                                    <Col span={3}><img alt="Image" onError={defaultIamge} src={API_PREFIX + "uploads/" + item.logoImage} className="clsimgSmall" /></Col>
                                    <Col span={21}>
                                        <><span className='clsTitle'>{item.title}</span>
                                            <div className='clssubDiv'><span>{blogType}</span><span>{countryType}</span></div>
                                            <div className='clssubDiv'><span>{dateNew}</span><span>{author}</span></div>
                                        </>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }
            </div>
        </Modal>
        <div draggable='true'>
            <FloatButton icon={<SearchOutlined />} type='primary' onClick={openModel} />
        </div>
    </>)
}
export default Search;